import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Colors from '../../consts/Colors';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

// import Cloud from "../../assets/weather/cloud.svg"

const getIcon = key => {
  // switch (key) {
  //   //cloud
  //   case 1009:
  //   case 1006:
  //   case 1030:
  //     return Cloud
  //     break;
  //   //rain
  //   case 1180:
  //   case 1183:
  //   case 1186:
  //     return (
  //     )
  //     break;
  //   // rain cloud
  //   case 1189:
  //   case 1192:
  //   case 1195:
  //   case 1246:
  //   case 1243:
  //     break;
  //   //rain cloud sun
  //   case 1240:
  //   case 1063:
  //     break;
  //   //snow
  //   case 1066:
  //   case 1069:
  //   case 1072:
  //   case 1114:
  //   case 1117:
  //   case 1204:
  //   case 1207:
  //   case 1210:
  //   case 1213:
  //   case 1216:
  //   case 1219:
  //   case 1222:
  //   case 1225:
  //   case 1237:
  //     break;
  //   //sun
  //   case 1000:
  //     break;
  //   //sun cloud
  //   case 1003:
  //     break;
  //   //thunder
  //   case 1273:
  //   case 1276:
  //   case 1279:
  //   case 396:
  //     break;
  // }
  return <img src={key} />;
};

const Weather = ({ geoLocation, getWeather, days, iconStyle }) => {
  if (!geoLocation) {
    return <span style={{ display: 'block', height: 55 }} />;
  }

  if (!geoLocation.forecast) {
    getWeather(geoLocation);
    return <Wrapper style={{ display: 'block', height: 55 }} />;
  }

  return (
    <Wrapper active={geoLocation.forecast !== undefined}>
      {geoLocation.forecast.map((forecast, i) => {
        if (i > days - 1) {
          return <span key={i} />;
        }
        return (
          <WeatherIcon key={i} style={iconStyle ? iconStyle : {}}>
            {getIcon(forecast.weather.icon)}
            <span>{moment(forecast.date).format('ddd')}</span>
          </WeatherIcon>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;

  /* transform: ${props => (props.active ? 'translateY(0%)' : 'translateY(185%)')};
  opacity: ${props => (props.active ? 1 : 0)};
  transition: all 0.3s ease-out;
  will-change: transform opacity; */
`;

const WeatherIcon = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  flex: 1;
  justify-content: center;
  width: 40px;
  position: relative;
  height: 55px;

  img {
    transform: scale(0.4);
    display: block;
    margin: 0 auto 10px;
  }

  &:last-of-type {
    border-right: 0px solid;
  }

  &:first-of-type {
    span {
      color: #fff;
    }

    svg {
      path {
        fill: rgba(255, 255, 255, 1);
      }
    }
  }

  span {
    color: rgba(255, 255, 255, 0.75);
    font-size: 14px;
    font-family: baskerville-display-pt, serif;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  svg {
    /* margin: 0 auto 10px; */
    path {
      /* fill: rgba(255, 255, 255, 0.75); */
      fill: #fff !important;
    }

    circle {
      /* fill: rgba(255, 255, 255, 0.75); */
      fill: rgba(255, 255, 255, 0) !important;
      stroke: #fff !important;
    }
  }
`;

export default withRouter(Weather);
