import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components/macro"
import Colors from "../../consts/Colors"
import { TimelineLite } from "gsap"
import ReactMapboxGl, { Layer, Feature, Marker } from "react-mapbox-gl"
import { withRouter } from "react-router-dom"
import Devices from "../../consts/Devices"

import MapBg from "../../assets/map-bg.png"
import Grid from "../../assets/grid.png"
import MapMarker from "../../assets/map-marker@2x.png"
import MapMarkerUser from "../../assets/user-loc@2x.png"
import MapMarkerInactive from "../../assets/map-marker-inactive@2x.png"
import MapMarkerStayInactive from "../../assets/map-marker-stay-inactive@2x.png"
import MapMarkerStayActive from "../../assets/map-marker-stay@2x.png"

const MapBox = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoibmV1c3R1ZGlvIiwiYSI6ImNqcnRhdmRydDF5NngzeXBzdTFkZ2h2NHAifQ.SmuHlMGPDvDf_fXM9Oh31Q",
  maxZoom: 11,
  minZoom: 2,
})

const Map = ({
  geoLocation,
  locations,
  currentLocationIndex,
  className,
  loadLocations,
  updateCurrentLocation,
  updateFilterTag,
  filterTag,
  loadLocation,
  userLocation,
}) => {
  let mobile = window.innerWidth < 1024
  let MB
  return (
    <Wrapper className={className}>
      <MapBoxWrapper>
        <MapBox
          style="mapbox://styles/neustudio/cjxx89ndo7xac1cocc7u1djni"
          // flyToOptions={
          //   filterTag !== null
          //     ? {
          //         curve: 2,
          //         speed: 1,
          //         easing(t) {
          //           return t;
          //         }
          //       }
          //     : {}
          // }
          // zoom={[5]}
          // center={
          //   geoLocation && filterTag !== null
          //     ? [geoLocation.location.lon, geoLocation.location.lat]
          //     : undefined
          // }
          fitBounds={
            geoLocation && filterTag !== null
              ? [
                  [-14.478655, 60.586967],
                  [3.9907, 49.936375],
                ]
              : null
          }
          onDragStart={() => {
            updateFilterTag(null)
          }}
          movingMethod={"easeTo"}
          onDragEnd={(map) => {
            let bounds = map.getBounds()
            let filter = {
              "fields.location[within]": `${bounds._sw.lat},${bounds._sw.lng},${bounds._ne.lat},${bounds._ne.lng}`,
              order: "fields.location",
            }
            loadLocations(filter)
          }}
          onStyleLoad={(map) => {
            MB = map
          }}
        >
          {locations.map((location, i) => {
            if (i == currentLocationIndex) {
              return <span key={i} />
            }
            return (
              <Marker
                coordinates={[location.location.lon, location.location.lat]}
                anchor="center"
                key={i}
                onClick={() => {
                  updateCurrentLocation(i)
                  if (window.innerWidth > 1024) {
                    loadLocation(location.slug)
                  }
                }}
              >
                <img
                  src={
                    location.isAccommodation
                      ? MapMarkerStayInactive
                      : MapMarkerInactive
                  }
                  style={{ width: 40 }}
                />
              </Marker>
            )
          })}
          {geoLocation && (
            <Marker
              coordinates={[geoLocation.location.lon, geoLocation.location.lat]}
              anchor="center"
            >
              <img
                src={
                  geoLocation.isAccommodation ? MapMarkerStayActive : MapMarker
                }
                style={{ width: geoLocation.isAccommodation ? 70 : 50 }}
              />
            </Marker>
          )}

          {userLocation && (
            <Marker
              coordinates={[userLocation.lng, userLocation.lat]}
              anchor="center"
            >
              <img src={MapMarkerUser} style={{ width: 50 }} />
            </Marker>
          )}
        </MapBox>
      </MapBoxWrapper>
    </Wrapper>
  )
}

const Zoom = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: none;

  @media ${Devices.laptop} {
    display: block;
  }

  > div {
    width: 44px;
    height: 44px;
    box-shadow: ${Colors.boxShadow};
    background: #ffffff;
    cursor: pointer;
    margin: 5px 0;
    position: relative;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 19px;
      color: #6a6a6a;
    }
  }
`

const Plus = styled.div``

const Minus = styled.div``

const MapBoxWrapper = styled.div`
  height: 200px;
  transition: height 1s;
  width: 100%;

  .mapboxgl-map {
    height: 200px;
  }

  @media ${Devices.laptop} {
    height: 100vh;
    .mapboxgl-map {
      height: 100vh;
      width: 65%;
      right: 0;
      position: fixed;
    }
  }

  @media ${Devices.layoutBreak} {
    .mapboxgl-map {
      width: calc(100% - 555px);
    }
  }
`

const BackgroundComp = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
`

const GridComp = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: url(${Grid});
  background-position: center;
  background-size: cover;
  z-index: 1;
  height: 33vh;
  @media ${Devices.laptop} {
    width: 100%;
    width: 65%;
    right: 0;
    left: auto;
    background-size: 210px;
    display: none;
  }
`

const Wrapper = styled.div`
  @media ${Devices.laptop} {
    width: 65%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
  }
`

export default React.memo(withRouter(Map))
