export const LOAD_LOCATIONS_PENDING = "LOAD_LOCATIONS_PENDING";
export const LOAD_LOCATIONS_SUCCESS = "LOAD_LOCATIONS_SUCCESS";
export const UPDATE_FILTER_TAG = "UPDATE_FILTER_TAG";

export const SET_APP = "SET_APP";

export const UPDATE_CURRENT_LOCATION = "UPDATE_CURRENT_LOCATION";

export const LOAD_LISTS_PENDING = "LOAD_LISTS_PENDING";
export const LOAD_LISTS_SUCCESS = "LOAD_LISTS_SUCCESS";

export const LOAD_LOCATION_PENDING = "LOAD_LOCATION_PENDING";
export const LOAD_LOCATION_SUCCESS = "LOAD_LOCATION_SUCCESS";

export const LOAD_RELATED_LOCATIONS_SUCCESS = "LOAD_RELATED_LOCATIONS_SUCCESS";
export const LOAD_RELATED_LOCATIONS_PENDING = "LOAD_RELATED_LOCATIONS_PENDING";

export const LOAD_LIST_PENDING = "LOAD_LIST_PENDING";
export const LOAD_LIST_SUCCESS = "LOAD_LIST_SUCCESS";

export const UPDATE_WEATHER = "UPDATE_WEATHER";

export const LOAD_STORY_LOCATION_SUCCESS = "LOAD_STORY_LOCATION_SUCCESS";
