import {
  LOAD_LOCATION_SUCCESS,
  LOAD_STORY_LOCATION_SUCCESS
} from "../actions/action-types.js";

const initialState = {
  data: null,
  storyLocation: null
};

export default function(state = { ...initialState }, action) {
  switch (action.type) {
    case LOAD_LOCATION_SUCCESS:
      return {
        ...state,
        data: action.payload
      };
      break;
    case LOAD_STORY_LOCATION_SUCCESS:
      return {
        ...state,
        storyLocation: action.payload
      };
      break;
    default:
      return state;
  }
}
