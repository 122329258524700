import React, { Component } from "react";
import styled, { css } from "styled-components/macro";
import Fonts from "../../consts/Fonts";
import Colors from "../../consts/Colors";
import Devices from "../../consts/Devices";

import Tick from "../../assets/tick@2x.png";
import Cross from "../../assets/cross@2x.png";

const textInput = ({
  label,
  value,
  placeholder,
  valid,
  update,
  type = "input",
  validated
}) => {
  return (
    <Wrapper active={value.length > 0}>
      <label htmlFor={"a"}>{label}</label>
      {type == "input" && (
        <input
          id="a"
          type="text"
          value={value}
          onChange={update}
          placeholder={placeholder}
        />
      )}

      {type == "textarea" && (
        <textarea
          id="a"
          type="text"
          rows="6"
          value={value}
          onChange={update}
          placeholder={placeholder}
        />
      )}
      <TickWrapper valid={valid} src={Tick} />
      {validated && <CrossWrapper valid={valid} src={Cross} />}
    </Wrapper>
  );
};

const TickWrapper = styled.img`
  position: absolute;
  right: 0;
  transform: translateX(50%);
  opacity: ${props => (props.valid ? 1 : 0)};
  transition: all 0.3s;
  width: 35px;
`;

const CrossWrapper = styled.img`
  position: absolute;
  right: 0;
  transform: translateX(50%);
  opacity: ${props => (props.valid ? 0 : 1)};
  transition: all 0.3s;
  width: 35px;
`;

const Wrapper = styled.div`
  background: #fff;
  border: 1px solid #cbcbcb;
  display: flex;
  flex-direction: column;
  padding: 9px 13px 7px;
  position: relative;

  ${props =>
    props.active &&
    `
    label {
      transform: translateY(-1px)!important;
    }
  `}

  label {
    font-size: 10px;
    line-height: 12px;
    color: #696969;
    position: relative;
    /* transform: translateY(10px); */
    transform: translateY(0px);
    transition: transform 0.3s;
  }

  input,
  textarea {
    outline: none;
    color: #000000;
    font-family: baskerville-display-pt, serif;
    border-width: 0px;
    resize: none;
    font-size: 16px;
    @media ${Devices.laptop} {
      font-size: 15px;
    }
  }
`;

export default textInput;
