import React, { Component } from "react"
import styled, { css } from "styled-components/macro"
import Colors from "../../consts/Colors"
import { NavLink, withRouter } from "react-router-dom"

import GoOffGridLogo from "../../assets/go-off-grid-logo@2x.png"

class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }
  render() {
    let canGoBack = this.props.canGoBack
    if (!this.props.seenSplash) {
      return <></>
    }
    return (
      <Wrapper
        loaded={this.props.loaded}
        onClick={() => {
          if (this.setState.isOpen) {
            this.setState({ isOpen: false })
          }
        }}
      >
        <TapTarget
          isOpen={this.state.isOpen}
          onClick={() => {
            if (canGoBack) {
              this.props.setApp({
                canGoBack: false,
                layered: false,
              })
              this.props.clearLocation()
              this.props.history.goBack()
            } else {
              this.setState({ isOpen: !this.state.isOpen })
            }
          }}
        >
          {canGoBack ? (
            <GoBack>
              <Arrow xmlns="http://www.w3.org/2000/svg" width="25" height="29">
                <text
                  data-name="→"
                  transform="rotate(180 12.5 2.5)"
                  fill="#b6dde0"
                  font-size="25"
                  font-family="LucidaGrande, Lucida Grande"
                >
                  <tspan x="0" y="0">
                    →
                  </tspan>
                </text>
              </Arrow>
              {/* <LogoWrapper className="logo">
                <img src={GoOffGridLogo} />
              </LogoWrapper> */}
            </GoBack>
          ) : (
            <Bars
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="18"
              isOpen={this.state.isOpen}
            >
              <g
                data-name="Group 126"
                fill="none"
                stroke="#fff"
                stroke-width="2"
              >
                <path data-name="Path 20" d="M0 9h23" id="middle" />
                <path data-name="Path 22" d="M0 1h23" id="top" />
                <path data-name="Path 21" d="M0 17h23" id="bottom" />
              </g>
            </Bars>
          )}
        </TapTarget>
        <MenuItems isOpen={this.state.isOpen}>
          {/* <NavLink
            to="/"
            exact
            activeClassName="active"
            onClick={() => {
              this.setState({ isOpen: false });
              this.props.setApp({
                view: 'DISCOVER',
                layered: false
              });
              this.props.clearLocation();
            }}
          >
            <LogoWrapper>
              <img src={GoOffGridLogo} />
            </LogoWrapper>
          </NavLink> */}
          <NavLink
            to="/"
            exact
            activeClassName="active"
            onClick={() => {
              this.setState({ isOpen: false })
              this.props.setApp({
                view: "DISCOVER",
                layered: false,
              })
              this.props.clearLocation()
            }}
          >
            <MenuItem>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28.121"
                height="28.121"
              >
                <g data-name="Group 81">
                  <g data-name="Group 79" fill="#1a1919">
                    <path
                      data-name="Path 26"
                      d="M14.061 0a14.061 14.061 0 1 0 14.06 14.061A14.061 14.061 0 0 0 14.061 0zm0 26.715a12.654 12.654 0 1 1 12.654-12.654 12.654 12.654 0 0 1-12.654 12.654z"
                    />
                    <circle
                      data-name="Ellipse 23"
                      cx=".703"
                      cy=".703"
                      r=".703"
                      transform="translate(13.358 23.903)"
                    />
                    <circle
                      data-name="Ellipse 24"
                      cx=".703"
                      cy=".703"
                      r=".703"
                      transform="translate(13.358 2.812)"
                    />
                    <circle
                      data-name="Ellipse 25"
                      cx=".703"
                      cy=".703"
                      r=".703"
                      transform="translate(24.606 13.358)"
                    />
                    <circle
                      data-name="Ellipse 26"
                      cx=".703"
                      cy=".703"
                      r=".703"
                      transform="translate(2.109 13.358)"
                    />
                    <path
                      data-name="Path 27"
                      d="M14.06 16.169a2.109 2.109 0 1 0-2.109-2.109 2.109 2.109 0 0 0 2.109 2.109zm0-2.812a.7.7 0 1 1-.7.7.7.7 0 0 1 .7-.7z"
                    />
                    <path
                      data-name="Path 28"
                      d="M20.036 5.725l-9.842 5.624a.675.675 0 0 0-.323.408l-2.812 9.84a.689.689 0 0 0 .239.745.717.717 0 0 0 .436.155.661.661 0 0 0 .352-.1l9.844-5.623a.675.675 0 0 0 .323-.408l2.81-9.841a.689.689 0 0 0-.239-.745.7.7 0 0 0-.788-.055zm-3.051 9.98l-8.1 4.626 2.25-7.916 8.1-4.626z"
                    />
                  </g>
                </g>
              </svg>
              <span>Discover</span>
            </MenuItem>
          </NavLink>
          <NavLink
            to="/lists"
            activeClassName="active"
            onClick={() => {
              this.setState({ isOpen: false })
              this.props.setApp({
                view: "LISTS",
                layered: false,
              })
              this.props.clearLocation()
            }}
          >
            <MenuItem>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26.885"
                height="17.172"
              >
                <g
                  data-name="Group 94"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-width="2"
                >
                  <path data-name="Path 42" d="M6.121 1h19.764" />
                  <path data-name="Path 53" d="M1.001 1h.884" />
                  <path data-name="Path 43" d="M6.121 8.586h19.764" />
                  <path data-name="Path 52" d="M1.001 8.586h.884" />
                  <path data-name="Path 44" d="M6.121 16.172h19.764" />
                  <path data-name="Path 51" d="M1.001 16.172h.884" />
                </g>
              </svg>
              <span>Top Lists</span>
            </MenuItem>
          </NavLink>
          {/* <NavLink
            to="/lists"
            activeClassName="active"
            onClick={() => {
              this.setState({ isOpen: false });
            }}
          >
            <MenuItem>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.476"
                height="17.377"
              >
                <g data-name="Group 95">
                  <path
                    data-name="Path 54"
                    d="M16.034.007a.87.87 0 0 1 .443.2l8.689 7.241a.869.869 0 0 1 0 1.33l-8.685 7.24a.869.869 0 0 1-1.421-.661V12.47c-1.959.019-8.7.391-13.621 4.7a.869.869 0 0 1-1.394-.923C2.478 8.747 9.791 5.432 15.06 3.992V.878a.869.869 0 0 1 .977-.869zm.76 2.715v1.919a.869.869 0 0 1-.652.842c-4.257 1.078-10.028 3.491-13.069 8.4a25.418 25.418 0 0 1 12.9-3.159.869.869 0 0 1 .824.869v1.91l6.471-5.385-6.471-5.394z"
                  />
                </g>
              </svg>
              <span>Share</span>
            </MenuItem>
          </NavLink> */}
          {/* <NavLink
            to="/add"
            activeClassName="active"
            onClick={() => {
              this.setState({ isOpen: false })
              this.props.setApp({
                view: "ADD",
                layered: false,
              })
            }}
          >
            <MenuItem>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.104"
                height="22.686"
              >
                <path
                  data-name="Path 36"
                  d="M7.284.003A7.691 7.691 0 0 0 0 7.68a7.824 7.824 0 0 0 .629 2.48c.059.158.121.314.168.449a.548.548 0 0 0 .022.067l5.612 11.213a1.4 1.4 0 0 0 1.235.8h.011a1.4 1.4 0 0 0 1.235-.8l5.634-11.224a.549.549 0 0 0-.247-.73.53.53 0 0 0-.718.236l-5.634 11.2a.289.289 0 0 1-.269.168.3.3 0 0 1-.281-.168L1.8 10.208c-.047-.132-.1-.274-.157-.426a7.13 7.13 0 0 1-.561-2.1 6.605 6.605 0 0 1 12.963-1.8.543.543 0 0 0 .662.382.554.554 0 0 0 .382-.673A7.713 7.713 0 0 0 7.677.003c-.132 0-.262-.007-.393 0zm.157 4.264a.539.539 0 0 0-.541.541v2.254H4.646a.539.539 0 0 0 0 1.077H6.9v2.257a.539.539 0 1 0 1.077 0V8.14h2.257a.539.539 0 1 0 0-1.077H7.98V4.808a.539.539 0 0 0-.539-.541z"
                  fill-rule="evenodd"
                />
              </svg>
              <span>Add a Location</span>
            </MenuItem>
          </NavLink> */}
          <a
            href="https://www.theshackletonwhisky.com/discover-shackleton-whisky/"
            target="_blank"
          >
            <MenuItem>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.399"
                height="42.069"
              >
                <path d="M10.322 13.422c-.85-.692-1.653-1.345-1.653-2.139V.513A.513.513 0 0 0 8.156 0H4.244a.513.513 0 0 0-.513.513v10.77c0 .794-.8 1.447-1.653 2.139-.973.792-2.077 1.689-2.077 3.023v23.5a2.13 2.13 0 0 0 2.126 2.122h8.144a2.13 2.13 0 0 0 2.128-2.127v-23.5c0-1.328-1.103-2.225-2.077-3.018zM4.757 1.027h2.886v1.255H4.757zm0 2.282h2.886v.458H4.757zm6.62 36.633a1.1 1.1 0 0 1-1.1 1.1h-8.15a1.1 1.1 0 0 1-1.1-1.1v-2.665h10.35v2.665zm0-3.692H1.027v-.867h10.35v.867zm0-1.776H1.027V20.995h2.115c1.188 0 2.179-1.1 3.01-1.1s1.947 1.1 2.961 1.1h2.264v13.48zm0-14.446h-2.26c-1.507 0-2.005-1.053-2.961-1.053s-1.889 1.053-3.124 1.053h-2v-3.583c0-.845.825-1.516 1.7-2.227 1-.813 2.032-1.653 2.032-2.935V4.791H7.65v6.493c0 1.282 1.033 2.122 2.032 2.935.873.711 1.7 1.382 1.7 2.227v3.583z" />
                <path
                  d="M2.126 25.355a9.433 9.433 0 0 1 4.037-1.193 8.508 8.508 0 0 1 3.9 1.193"
                  fill="none"
                  stroke="#000"
                />
              </svg>
              <span>Discover Shackleton</span>
            </MenuItem>
          </a>
          <a
            href="http://offgrid.theshackletonwhisky.com/privacy-policy"
            target="_blank"
          >
            <MenuItem>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.004"
                height="26.605"
              >
                <g data-name="Group 518">
                  <path
                    data-name="Path 144"
                    d="M15.837 26.606H3.167A3.167 3.167 0 0 1 0 23.438v-7.969a3.167 3.167 0 0 1 3.167-3.167h12.67A3.167 3.167 0 0 1 19 15.469v7.969a3.167 3.167 0 0 1-3.163 3.168zM3.167 13.569a1.9 1.9 0 0 0-1.9 1.9v7.969a1.9 1.9 0 0 0 1.9 1.9h12.67a1.9 1.9 0 0 0 1.9-1.9v-7.969a1.9 1.9 0 0 0-1.9-1.9z"
                  />
                  <path
                    data-name="Path 145"
                    d="M14.57 13.569H4.434a.633.633 0 0 1-.633-.634V5.7a5.7 5.7 0 1 1 11.4 0v7.234a.633.633 0 0 1-.631.635zM5.068 12.3h8.868V5.7a4.434 4.434 0 1 0-8.868 0z"
                  />
                  <path
                    data-name="Path 146"
                    d="M9.502 23.109a1.824 1.824 0 0 1-.849-.2l-1.267-.633a1.9 1.9 0 0 1-1.051-1.701v-3.016a1.9 1.9 0 0 1 1.9-1.9h2.534a.6335.6335 0 0 1 0 1.267H8.235a.633.633 0 0 0-.633.633v3.015a.646.646 0 0 0 .355.57l1.267.633a.621.621 0 0 0 .557 0l1.976-.988a.646.646 0 0 1 .849.279.633.633 0 0 1-.291.849l-1.964.988a1.824 1.824 0 0 1-.849.2z"
                  />
                  <path
                    data-name="Path 147"
                    d="M10.77 20.271a.6.6 0 0 1-.443-.19L9.06 18.814a.62720371.62720371 0 0 1 .887-.887l.824.811 2.724-2.711a.62720371.62720371 0 0 1 .887.887l-3.167 3.167a.6.6 0 0 1-.445.19z"
                  />
                </g>
              </svg>
              <span>Privacy Policy</span>
            </MenuItem>
          </a>
        </MenuItems>
        <Opacity isOpen={this.state.isOpen} />
      </Wrapper>
    )
  }
}

const GoBack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  .logo {
    position: absolute;
    padding: 0;
    background: transparent;
    top: 70px;
  }
`

const LogoWrapper = styled.div`
  background: ${Colors.blue};
  text-align: center;
  padding: 6px 0 20px 0;
  img {
    width: 50px;
  }
`

const Bars = styled.svg`
  margin: 0 auto;
  overflow: visible !important;
  #top {
    transition: transform 0.3s;
  }
  #bottom {
    transition: transform 0.3s;
  }

  path {
    transform-origin: 27%;
    stroke: ${(props) => (props.isOpen ? "#fff" : Colors.blue)};
  }

  ${(props) =>
    props.isOpen &&
    css`
      transform: translateX(5px);

      #middle {
        opacity: 0;
      }

      #top {
        transform: translateY(50%) rotate(-45deg);
      }

      #bottom {
        transform: translateY(-50%) rotate(45deg);
      }
    `}
`

const Arrow = styled.svg`
  margin: 0 auto;
  overflow: visible !important;
`

const MenuItems = styled.div`
  z-index: 4;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 89px;
  left: ${(props) => (props.isOpen ? "20px" : "-200000px")};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: opacity 0.3s;
  transition-delay: ${(props) => (props.isOpen ? "0.3s" : "0s")};

  a.active {
    background: #f8f8f8;
    span {
      font-weight: 600;
      color: #000000;
    }
  }

  a {
    text-decoration: none;
    display: block;
    background: #fff;
  }
`

const MenuItem = styled.div`
  padding: 18px 8px;
  text-align: center;
  width: 72px;
  border-top: 1px solid #f0f0f0;

  span {
    font-size: 10px;
    line-height: 12px;
    color: #696969;
    display: block;
    margin-top: 10px;
    font-weight: 300;
  }
`

const TapTarget = styled.div`
  width: ${(props) => (props.isOpen ? "72px" : "50px")};
  height: ${(props) => (props.isOpen ? "72px" : "50px")};
  background: ${(props) => (props.isOpen ? Colors.blue : "#fffffe")};
  position: fixed;
  top: 17px;
  left: 20px;
  z-index: 4;
  box-shadow: ${Colors.boxShadow};
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;
`

const Wrapper = styled.div`
  position: relative;
  z-index: 101;
  opacity: ${(props) => (props.loaded ? 1 : 0)};
  transition: opacity 0.3s;
`

const Opacity = styled.div`
  position: fixed;
  top: ${(props) => (props.isOpen ? 0 : "-200vh")};
  left: 0;
  right: 0;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  bottom: ${(props) => (props.isOpen ? 0 : "auto")};
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
  transition: opacity 0.3s;
`

export default withRouter(Menu)
