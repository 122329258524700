import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

const ListLoader = props => {
  useEffect(() => {
    props.loadList(props.match.params.slug);
    props.setApp({ view: 'LIST' });
  }, [props.match.params.slug]);
  return <span />;
};

export default withRouter(ListLoader);
