import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components/macro"
import Colors from "../../consts/Colors"
import Devices from "../../consts/Devices"
import { withRouter, Link } from "react-router-dom"
import Skeleton from "react-loading-skeleton"
import { useInView } from "react-intersection-observer"

import Pulse from "../../assets/pulse.svg"
import BedIcon from "../../assets/bedIcon.svg"
import WifiIcon from "../../assets/wifiIcon.svg"

import Weather from "../Weather"

const SlideComp = ({
  geoLocation,
  index,
  active,
  onMouseEnter,
  className,
  getWeather,
  onClick,
  locationsLoading,
  onLocation,
  filterTag,
}) => {
  const slideRef = useRef(null)
  const [imageLoaded, setImageLoaded] = useState(false)
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  })

  const loadBackgroundImg = (url) => {
    const img = new Image()

    img.onload = function () {
      setImageLoaded(true)
    }
    img.src = url
  }

  useEffect(
    (src) => {
      if (!inView || imageLoaded) return
      setImageLoaded(false)
      loadBackgroundImg(geoLocation.images[0])
    },
    [inView]
  )
  if (!inView) {
    return <span ref={ref}></span>
  }

  return (
    <Wrapper
      key={index}
      index={index}
      onMouseEnter={onMouseEnter}
      className={className}
      style={!imageLoaded ? {} : { animation: `fadeIn 0.4s` }}
      ref={ref}
    >
      {!imageLoaded || locationsLoading ? (
        <SkelWrapper>
          <Skeleton width={300} height={"100%"} />
        </SkelWrapper>
      ) : (
        <Link
          to={`/location/${geoLocation.slug}`}
          onClick={() => {
            if (onClick) {
              // let rect = slideRef.current.getBoundingClientRect();
              // onClick(rect);
              onClick()
            }
          }}
        >
          <Inner geoLocation={geoLocation} ref={slideRef} active={active}>
            <InnerInner active={active || onLocation}>
              {active && <ActiveDot className="active-dot" />}
              <Title>{geoLocation.title}</Title>
              <Address>{geoLocation.address}</Address>
              <Description>
                {geoLocation.description.substring(0, 100)}...
              </Description>
              {/* {getWeather && (
                <Weather
                  geoLocation={geoLocation}
                  days={3}
                  getWeather={active ? getWeather : () => {}}
                />
              )} */}
              {geoLocation.isAccommodation && (
                <Details>
                  <Item>
                    <img src={BedIcon} alt="" />
                    <span>{geoLocation.hosts ? geoLocation.hosts : ""}</span>
                  </Item>
                  <Item>
                    <h4>{geoLocation.price ? geoLocation.price : ""}</h4>
                    <span>
                      {geoLocation.priceSub ? geoLocation.priceSub : ""}
                    </span>
                  </Item>
                  <Item>
                    <img src={WifiIcon} alt="" />
                    <span>No Wifi</span>
                  </Item>
                </Details>
              )}
            </InnerInner>
          </Inner>
        </Link>
      )}
    </Wrapper>
  )
}

const SkelWrapper = styled.div`
  opacity: 0;
  @media ${Devices.laptop} {
    opacity: 1;
  }
`

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`

const Item = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;

  border-right: 1px solid rgba(255, 255, 255, 0.2);

  &:last-child {
    border-width: 0;
  }
  h4,
  span {
    color: #fff;
    display: block;
    font-family: baskerville-display-pt, serif;
    text-align: center;
  }

  h4 {
    font-size: 20px;
    line-height: 29px;
    font-weight: 100;
  }

  img {
    margin: 0 auto;
    display: block;
    height: 26px;
  }

  span {
    font-size: 14px;
    margin-top: 15px;
  }
`

const ActiveDot = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 999em;
  background: #b7994e;
  border: 2px solid #fff;
  margin-bottom: 15px;
  transition: all 0.3s ease-in;
  opacity: 0;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    background: url(${Pulse});
    background-size: cover !important;
    background-position: center !important;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    animation: pulse 1.5s ease 0s infinite;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: scale(2);
    }
  }
`

const Wrapper = styled.div`
  min-height: 390px;
  /* padding: 10px 23px; */
  padding: 10px 0px;
  display: flex;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Inner = styled.div`
  background: url(${(props) => props.geoLocation.images[0]}?w=500);
  background-size: cover !important;
  background-position: center !important;
  margin: 0 10px 0 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  overflow: hidden;
  box-shadow: ${Colors.boxShadow};

  a {
    width: 100%;
  }

  ${(props) =>
    props.active &&
    `
      .active-dot{
        opacity: 1;
      } 
    `}
`

const InnerInner = styled.div`
  padding: 20px 19px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5116421568627452) 69%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: all 0.3s ease-out;
  will-change: transform, opacity;
  ${(props) =>
    props.active
      ? `
    transform: translateY(0%);
    opacity: 1;
    `
      : `
    transform: translateY(100%);
    opacity: 0;
    `}
  @media ${Devices.laptop} {
    transform: translateY(0%);
    opacity: 1;
  }
`

const Title = styled.h2`
  font-size: 22px;
  line-height: 25px;
  color: #fff;
  margin-bottom: 9px;

  @media ${Devices.laptop} {
    font-size: 23px;
  }
`

const Address = styled.h4`
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  margin-bottom: 9px;
  font-weight: 500;
  @media ${Devices.laptop} {
    font-size: 14px;
  }
`

const Description = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  font-family: baskerville-display-pt, serif;
  font-weight: 100;
  margin-bottom: 11px;
  @media ${Devices.laptop} {
    font-size: 16px;
    line-height: 21px;
  }
`

export default React.memo(withRouter(SlideComp))
