import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';

const Privacy = ({}) => {
  return (
    <Wrapper>
      <div>
        <p>
          <strong>Privacy Policy – Whyte and Mackay</strong>
        </p>
        <p>
          The following privacy policy is specific to websites operated by Whyte and Mackay Limited
          ("Whyte &amp; Mackay"), having its registered office at 319 St Vincent Street, Glasgow, G2
          5RG, Scotland, United Kingdom (Company number: SC014456). The policy sets out how personal
          information is collected, used and stored when using Whyte &amp; Mackay websites, and your
          rights in relation to this personal information.
        </p>
        <p>
          If you have any questions about the collection or use of your information through any
          websites operated by Whyte &amp; Mackay, you can contact Whyte &amp; Mackay by e-mail to
          cosec@whyteandmackay.com or by post to the above address, for the attention of the Legal
          Department.
        </p>
        <p>
          Whyte &amp; Mackay is committed to ensuring that your privacy is protected. Any and all
          information provided will only be used in accordance with this privacy statement. Whyte
          &amp; Mackay may update this policy from time to time, following company or legal
          requirements. Any changes will be communicated through this page.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>What personal information is collected:</strong>
        </p>
        <p>
          We collect and process the following information which you have chosen to provide to us:
        </p>
        <ul>
          <li>
            Information that you provide by filling in registration forms on our site. This includes
            information provided where you have subscribed to our newsletter service or where you
            have requested further services from us.
          </li>
          <li>
            Your age / date of birth which we collect to verify that you are of legal purchase age
            to visit our sites.
          </li>
          <li>
            Details of transactions you carry out through our sites, and information provided by you
            (e.g. your name, telephone number, e-mail and postal addresses) to ensure the fulfilment
            of any orders which you place with us.
          </li>
          <li>If you contact us, we may keep a record of that correspondence.</li>
          <li>
            Details of your visits to our site [including, but not limited to, traffic data,
            location data, weblogs and other communication data, whether this is required for our
            own billing purposes or otherwise] and the resources that you access.
          </li>
          <li>
            IP addresses – when you visit our site, we will automatically receive your IP address, a
            unique identifier for your computer or other access device. This is statistical data and
            does not identify you as an individual.
          </li>
        </ul>
        <p>
          We will only use the personal information we collect: for our legitimate business
          interests, including to improve your consumer experience of our products and services; to
          provide you with specific information and services that you request; and with additional
          information and services that may be of interest to you.&nbsp;<strong>&nbsp;</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>How personal information is collected</strong>
        </p>
        <p>
          Personal identifiable information is only collected through the actions explained above.
          You can check the information that we hold about you by visiting your account or
          contacting us. If you find any inaccuracies or no longer wish this information to be held
          we will delete or correct it promptly. The personal information that we hold will be held
          securely in accordance with our internal security policy and the law. We will not transfer
          or sell your information. If you have any questions/comments about privacy or wish to
          request what data is currently held, you should email us at{' '}
          <span>
            <a href="mailto:data@whyteandmackay.com">data@whyteandmackay.com</a>
          </span>
          . Alternatively, if you believe your information is being processed or held incorrectly
          you can complain to us and/or to the data protection regulator – the Information
          Commissioner’s Office (‘ICO’). To contact the ICO you can follow this link:
          <strong> https://ico.org.uk/.</strong>
        </p>
        <p>
          <strong>Use of personal information </strong>
        </p>
        <p>We use the information you provide for the following purposes:</p>
        <ul>
          <li>
            To ensure that content of our websites is presented in the most effective manner for you
            and for your computer and/or device;
          </li>
          <li>
            To provide you with information, products or services that you request from us or which
            we feel may interest you, where you have consented to be contacted for such purposes;
          </li>
          <li>
            To allow you to participate in any interactive features of the website(s), when you
            choose to do so;
          </li>
          <li>To notify you about changes to our products and services; and,</li>
          <li>To process and fulfil online purchases made by you.</li>
        </ul>
        <p>&nbsp;</p>
        <p>
          We also use personal information to further understand the buying behaviour of our
          registered users, and to continually improve our customers purchasing experience. We may
          also use some information to perform marketing and promotional activities focused on our
          products and services.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>Your consent</strong>
        </p>
        <p>
          By consenting to submit information to Whyte and Mackay you agree to the collection and
          use of this information by Whyte and Mackay and our service provider, when applicable.
          This consent will be taken as lasting for a period of two years. If there has been no
          positive response for two years post-consent, we will contact you to ensure the consent is
          still valid. If we receive no response we will consider the consent to be withdrawn and we
          will remove any personal information currently held.
        </p>
        <p>
          If you no longer wish to receive information about our products or services you can remove
          your consent by clicking the unsubscribe link at any time, which exists at the bottom of
          all marketing emails sent by Whyte and Mackay.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>Data security</strong>
        </p>
        <p>
          We have designed our site to protect the information we collect online from unauthorised
          access. We safeguard your private information by implementing the appropriate physical,
          electronic and managerial procedure necessary to protect your privacy. To further protect
          your security, we also take the reasonable steps to verify your identity before granting
          access to your personal profile or making data changes. We are committed to protecting
          your privacy and have security measures in place to prevent unauthorised access to and
          disclosure of your personal information.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>Improving our service</strong>
        </p>
        <p>
          Whyte and Mackay does not sell, trade or rent your personal information to others. To
          better tailor our services to our customers' needs, we use non-identifying and aggregate
          information to help us make decisions on how to improve our portfolio of websites. We may
          also share this generic and unidentifiable information with our advertisers and other
          parties with whom we have a contractual relationship. For example, we may tell advertisers
          which areas of the site have received the most customer traffic overall or how many
          anonymised customer types have visited certain pages. All parties involved in this process
          will be subject to GDPR regulations and Whyte &amp; Mackay will take reasonable measures
          to ensure they are GDPR compliant. We do not disclose identifying information of
          individual customers with advertisers or share the personally identifiable information
          submitted to us by any other means without explicit consent to do so.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>Cookies</strong>
        </p>
        <p>
          Cookies are alphanumeric identifiers that enable our systems to recognise your browser and
          to provide a rich shopping experience including features such as recommend products and
          loyalty discounts, as well as the storage of items in your Shopping Basket between visits.
          The Help menu in most common browsers will tell you how to prevent your browser from
          accepting new cookies, how to have the browser notify you when you receive a new cookie
          and how to disable cookies altogether. However, because cookies allow you to take
          advantage of some of Whyte and Mackay’s essential features, we recommend that you leave
          them turned on.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Analytic tools</strong>
        </p>
        <p>
          Whyte and Mackay may from time to time share anonymised information with third party
          analytical resources for the purposes of better understanding customer behaviour and
          performance, website performance, improved ad relevance and any other analytical process
          to give Whyte and Mackay a better understanding of how to develop its offering to its
          customers. For example, through Google Analytics.
        </p>
        <p>
          Google Analytics is a system used by Whyte &amp; Mackay to record information about visits
          to our websites using cookies. These cookies are used to differentiate between first time
          visitors to a website and repeat visitors. They also allow us to ensure our website
          performs as well as possible for our users. The cookies are able to collect an anonymous
          customer number when a user is logged into the website, allowing our systems to ensure
          accuracy of Google Analytics data and help us ensure your website experience remains
          relevant across devices. They do not contain any personally identifiable information. You
          can find out more about how Google uses cookies at
          http://www.google.co.uk/policies/privacy/ (English only).
        </p>
        <p>
          Any third parties employed for the purpose of the aforementioned analytical purposes, are
          contractually obliged to use the data only for the analytical purposes set out in the
          agreement between Whyte and Mackay and the third party. Whyte and Mackay will ensure that
          the third party is contractually required to maintain a level of security around the
          shared data, at least as safe as Whyte and Mackay itself employs. Failure on the part of
          the third party to meet its contractual obligations will result in Whyte and Mackay
          recovering the data, and taking all reasonable actions to ensure the third party no longer
          has access to the shared data. Any third party cookies used on Whyte and Mackay’s systems
          will adhere to the same standards as first party cookies and not collect any personally
          identifiable information.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>International disclosure</strong>
        </p>
        <p>
          The above privacy statement has been created for use within the United Kingdom and Europe.
          Other territories may be subject to different legislation which this policy may not
          coincide with.
        </p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  > div {
    padding: 75px 35px;
    a {
      text-decoration: underline !important;
    }
    ul {
      margin-left: 25px;

      li + li {
        margin-top: 15px;
      }

      li {
        line-height: 1.5;
      }
    }

    p {
      line-height: 1.5;
      margin-top: 15px;
    }
  }
`;

export default Privacy;
