import {
  UPDATE_CURRENT_LOCATION,
  UPDATE_FILTER_TAG,
  UPDATE_WEATHER
} from "../actions/action-types.js";

import axios from "axios";

const Actions = {
  updateCurrentLocation: i => async dispatch => {
    dispatch({
      type: UPDATE_CURRENT_LOCATION,
      payload: i
    });
  },
  updateFilterTag: i => async dispatch => {
    dispatch({
      type: UPDATE_FILTER_TAG,
      payload: i
    });
  },
  getWeather: geoLocation => async dispatch => {
    // let res = await axios.get(
    //   `//api.openweathermap.org/data/2.5/forecast?lat=${
    //     geoLocation.location.lat
    //   }&lon=${geoLocation.location.lon}&appid=51a2f69e064f7a80b669f9839ea8be12`
    // );

    let res = await axios.get(
      `https://api.apixu.com/v1/forecast.json?key=db75a731742847c99ee101534192307&q=${
        geoLocation.location.lat
      },${geoLocation.location.lon}&days=7`
    );

    let forecasts = [];

    res.data.forecast.forecastday.map(item => {
      forecasts.push({
        date: item.date,
        weather: item.day.condition
      });
    });

    geoLocation.forecast = forecasts;

    dispatch({
      type: UPDATE_WEATHER,
      payload: geoLocation
    });
  }
};

export default Actions;
