import Devices from "./Devices";

export default {
  title: `
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #1A1919;
        font-size: 28px;
        line-height: 32px;
        @media ${Devices.laptop} {
          font-size: 50px;
          line-height: 60px;
        }
    `
};
