import React, { useState, useEffect, useRef } from "react"
import styled, { css } from "styled-components/macro"
import Colors from "../../consts/Colors"
import { withRouter } from "react-router-dom"

const Options = ({
  size,
  updateFilterTag,
  filterTagIndex,
  className,
  options,
}) => {
  return (
    <OptionsWrapper className={className}>
      {options.map((option, i) => {
        return (
          <Option
            key={i}
            active={filterTagIndex == i ? true : false}
            onClick={() => {
              updateFilterTag(i)
            }}
          >
            <span>{option}</span>
          </Option>
        )
      })}
      {/* <Option
        active={filterTagIndex == 0 ? true : false}
        onClick={() => {
          updateFilterTag(0);
        }}
      >
        <span>New</span>
      </Option>
      <Option
        active={filterTagIndex == 1 ? true : false}
        onClick={() => {
          updateFilterTag(1);
        }}
      >
        <span>Popular</span>
      </Option>
      <Option
        onClick={() => {
          updateFilterTag(2);
        }}
        active={filterTagIndex == 2 ? true : false}
      >
        <span>Nearby Me</span>
      </Option> */}
    </OptionsWrapper>
  )
}

const OptionsWrapper = styled.div`
  padding: 0 31px;
  display: flex;
  margin: 25px 0 25px;
  padding-right: 0;
`

const Option = styled.div`
  margin-right: 35px;
  cursor: pointer;
  span {
    color: #696969;
    font-size: 12px;
    padding-bottom: 12px;
    transition: color 0.3s;
  }
  ${(props) =>
    props.active &&
    css`
      span {
        font-weight: 600;
        color: #1a1919;
        border-bottom: 2px solid ${Colors.blue};
      }
    `}
`

export default withRouter(Options)
