import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { withRouter, Link } from 'react-router-dom';
import Fonts from '../../consts/Fonts';
import Colors from '../../consts/Colors';
import Devices from '../../consts/Devices';
import { trackPage } from 'react-with-analytics';

import DataActions from '../../actions/dataActions';

import Hero from '../../components/Hero';
import Carousel from '../../components/Carousel';
import Weather from '../../components/Weather';

class List extends Component {
  componentDidMount() {
    window.scroll({
      top: 0,
      left: 0
    });

    // this.props.loadList(this.props.match.params.slug);
    trackPage(window.location.pathname + window.location.search);
  }

  render() {
    let { list } = this.props;

    if (list.notLoaded) {
      return <span />;
    }

    return (
      <OuterWrapper>
        <LocationWrapper>
          <Title>{list.title}</Title>

          <Description>
            <p>{list.description}</p>
          </Description>

          <LocationsList>
            {list.locations.map((location, i) => {
              return (
                <Location key={i}>
                  <h3>{`${i + 1}. ${location.title}`}</h3>
                  <Hero
                    images={location.images.map(image => {
                      return image.fields.file.url;
                    })}
                  />
                  <p>{location.description}</p>

                  <ListItem key={i}>
                    <Link
                      to={`/location/${location.slug}`}
                      onClick={() => {
                        this.props.setApp({
                          canGoBack: true
                        });
                      }}
                    >
                      {/* <ItemImage image={location.images[0].fields.file.url} /> */}
                      <div>
                        <ItemTitle>{location.title}</ItemTitle>
                        <ItemSubTitle>{location.address}</ItemSubTitle>
                      </div>
                      <ItemLink>→</ItemLink>
                    </Link>
                  </ListItem>
                </Location>
              );
            })}
          </LocationsList>
        </LocationWrapper>
      </OuterWrapper>
    );
  }
}

const ItemLink = styled.div`
  background: ${Colors.blue};
  width: 44px;
  padding: 0 !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-size: 18px;

  @media ${Devices.laptop} {
    position: absolute;
    right: 0;
    height: 70px;
    width: 70px;
  }
`;

const ItemImage = styled.div`
  background: url(${props => props.image});
  background-size: cover !important;
  background-position: center !important;
  width: 65px;
  padding: 0 !important;
`;

const ItemTitle = styled.h3`
  color: ${Colors.nearBlack};
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  margin-bottom: 5px !important;
`;

const ItemSubTitle = styled.h4`
  color: ${Colors.grey};
  font-size: 10px;
  line-height: 12px;
  font-weight: 300;
`;

const ListItem = styled.div`
  a {
    background: #fff;
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
    border: 1px solid #efefef;
    cursor: pointer;

    @media ${Devices.laptop} {
      justify-content: flex-start;
      position: relative;
    }
    > div {
      padding: 18px;
    }
  }
`;

const HeroWrapper = styled(Hero)`
  @media ${Devices.laptop} {
    width: 64vw !important;
    > div {
      height: 100%;
    }
  }
`;

const OuterWrapper = styled.div`
  @media ${Devices.laptop} {
    display: flex;
    flex-direction: row-reverse;
  }
`;

const Location = styled.div`
  p {
    font-family: baskerville-display-pt, serif;
    font-size: 15px;
    line-height: 20px;
    color: #696969;
    margin-top: 15px;
    @media ${Devices.laptop} {
      font-size: 17px;
      line-height: 23px;
    }
  }
  h3 {
    margin-bottom: 15px;
  }
  margin: 50px 0;

  a {
    cursor: pointer;
  }
`;

const LocationsList = styled.div`
  padding: 0 31px;
`;

const Description = styled.div`
  p {
    font-family: baskerville-display-pt, serif;
    padding: 0 31px;
    font-size: 15px;
    line-height: 20px;
    color: #696969;
    @media ${Devices.laptop} {
      font-size: 17px;
      line-height: 23px;
    }
  }
`;

const Title = styled.h2`
  font-size: 26px;
  line-height: 21px;
  color: ${Colors.nearBlack};
  padding: 10px 31px 20px 31px;
`;

const LocationWrapper = styled.div`
  margin: 19px 0;
  max-width: 100%;
  position: relative;
  @media ${Devices.laptop} {
    padding: 90px 0px 0px;
    /* max-width: 565px; */
    margin: 0 auto;
  }
`;

const mapStateToProps = state => {
  return {
    list: state.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadList: slug => {
      dispatch(DataActions.loadList(slug));
    },
    setApp: e => {
      dispatch({
        type: 'SET_APP',
        payload: e
      });
    }
  };
};

const Index = connect(
  mapStateToProps,
  mapDispatchToProps
)(List);

export default withRouter(Index);
