import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import Colors from "../../consts/Colors";
import Devices from "../../consts/Devices";
import { withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Torn from "../../assets/torn.svg";
import TornV from "../../assets/torn-v.svg";

const Layout = ({ first, second, loaded, locked }) => {
  return (
    <Wrapper locked={locked}>
      <First loaded={loaded}>
        <TornEdge />
        <CSSTransition in={loaded} timeout={3000} classNames="my-node">
          {first}
        </CSSTransition>
      </First>
      <Second>{second}</Second>
    </Wrapper>
  );
};

const First = styled.div`
  position: relative;
  background: #fff;

  @media ${Devices.laptop} {
    width: 35%;
    min-height: 100vh;
    z-index: 3;
    max-width: 555px;
  }

  @media ${Devices.layoutBreak} {
    width: 555px;
  }
  /* 
  > div {
    opacity: 0;
  } */
`;

const Second = styled.div`
  @media ${Devices.laptop} {
    /* width: 75%; */
    .hero {
      position: fixed;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${props =>
    props.locked &&
    `
    overflow: hidden;
  `}
  @media ${Devices.laptop} {
    flex-direction: row;
  }

  .my-node-enter {
    opacity: 0;
  }

  .my-node-enter-active {
    opacity: 1;
    transition: opacity 2s;
  }

  .my-node-enter-done {
    opacity: 1;
    transition: opacity 2s;
  }

  .my-node-exit {
    opacity: 1;
  }
  .my-node-exit-active {
    opacity: 0;
    transition: opacity 2s;
  }

  .my-node-exit-done {
    opacity: 0;
  }
`;

const TornEdge = styled.div`
  background: url(${Torn}) center / cover no-repeat;
  height: 20px;
  width: 101%;
  position: absolute;
  top: -15px;
  right: 0;
  left: 0;
  z-index: 1;
  margin-left: -1px;
  @media ${Devices.laptop} {
    background: url(${TornV}) top center / 100% repeat;
    bottom: 0;
    height: 100%;
    top: -1px;
    width: 20px;
    right: -19px;
    left: auto;
    position: absolute;
  }
`;

export default withRouter(Layout);
