import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import Colors from '../../consts/Colors';
import Devices from '../../consts/Devices';
import { TimelineLite } from 'gsap';
import { withRouter } from 'react-router-dom';

import GoOffGridLogo from '../../assets/go-off-grid-logo@2x.png';
import ShackletonLogo from '../../assets/shackleton-logo@2x.png';
import MapBg from '../../assets/map-bg.png';
import Grid from '../../assets/grid.png';

const Logo = ({ loaded }) => {
  // const [started, setStarted] = useState(false);
  // const map = useRef(null);
  // const innerContent = useRef(null);
  // const tl = new TimelineLite({});

  // useEffect(() => {
  //   if (started) {
  //     return;
  //   }

  //   let ease = "Power1.linear";

  //   tl.to(map.current, 3, { transform: "translateX(-50%)" });
  //   tl.to(map.current, 3, { transform: "translateX(-25%)" });

  //   setStarted(true);
  // });

  return (
    <Wrapper loaded={loaded}>
      {/* <div>
        <GoOffGridLogoComp src={GoOffGridLogo} />
      </div> */}
      <div>
        <a href="/">
          <ShackletonLogoComp src={ShackletonLogo} />
        </a>
      </div>
      {/* <GridComp loaded={loaded} />
      <BackgroundComp src={MapBg} loaded={loaded} ref={map} /> */}
    </Wrapper>
  );
};

const GridComp = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: url(${Grid});
  background-position: center;
  background-size: cover;
  z-index: 1;
  /* height: ${props => (props.loaded ? '33vh' : '100vh')};
  display: ${props => (props.loaded ? 'none' : 'block')}; */
  
  @media ${Devices.laptop} {
    background-size: 210px;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  /* bottom: ${props => (props.loaded ? '106vh' : '0vh')}; */
  z-index: 5;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: bottom 1s;

  > div {
    padding-top: 20px;
  }
`;

const BackgroundComp = styled.img`
  height: 100vh;
  position: absolute;
  top: 0;
  left: ${props => (props.loaded ? '-2000px' : '0px')};
  opacity: ${props => (props.loaded ? 0 : 1)};
`;

const GoOffGridLogoComp = styled.img`
  margin-bottom: 25px;
  width: 68px;
  position: relative;
  z-index: 3;
`;

const ShackletonLogoComp = styled.img`
  width: 111px;
  position: relative;
  /* z-index: 3; */
  z-index: 10;
  @media ${Devices.laptop} {
    width: 135px;
  }
`;

export default withRouter(Logo);
