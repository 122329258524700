import {
  LOAD_LISTS_SUCCESS,
  UPDATE_FILTER_TAG
} from "../actions/action-types.js";

const initialState = {
  lists: [],
  filterTag: 0
};

export default function(state = { ...initialState }, action) {
  switch (action.type) {
    case LOAD_LISTS_SUCCESS:
      return {
        ...state,
        lists: action.payload
      };
      break;
    case UPDATE_FILTER_TAG:
      return {
        ...state,
        filterTag: action.payload
      };
      break;
    default:
      return state;
  }
}
