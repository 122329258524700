import React, { useState, useEffect, useRef } from "react"
import { withRouter } from "react-router-dom"

const StayLoader = (props) => {
  useEffect(() => {
    props.updateFilterTag(3)
    props.loadLocations({ "fields.isAccommodation": true })
  }, [])
  return <span />
}

export default withRouter(StayLoader)
