import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

const ListsLoader = props => {
  useEffect(() => {
    props.setApp({ view: "ADD" });
    props.clearLocation();
  }, [props.match.params.slug]);
  return <span />;
};

export default withRouter(ListsLoader);
