import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { withRouter, Link } from 'react-router-dom';
import { Stage, Layer, Rect, Text, Image as Img } from 'react-konva';
import Konva from 'konva';

import DataActions from '../../actions/dataActions';
import LocationActions from '../../actions/locationActions';

import Logo from '../../assets/shackleton-logo@2x.png';
import OffGridLogo from '../../assets/go-off-grid-logo@2x.png';
import iconVal from '../../assets/bulls-eye.svg';
import Pin from '../../assets/add.svg';
import BigGrid from '../../assets/big-grid.png';

const markerURL = 'https://offgrid.theshackletonwhisky.com/map-marker.png';
let map, logo, gridLogo, icon, pin, bigGrid;

class Story extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false };
  }

  componentDidMount() {
    let slug = this.props.match.params.slug;
    window.setTimeout(() => {
      this.props.loadStoryLocation(slug);
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    let { storyLocation } = this.props;
    if (prevProps.storyLocation == storyLocation) {
      return;
    }

    bigGrid = new Image();
    bigGrid.src = BigGrid;
    bigGrid.onload = () => {
      this.setState({ loaded: true });
    };

    pin = new Image();
    pin.src = Pin;
    pin.onload = () => {
      this.setState({ loaded: true });
    };

    logo = new Image();
    logo.src = Logo;
    logo.onload = () => {
      this.setState({ loaded: true });
    };

    gridLogo = new Image();
    gridLogo.src = OffGridLogo;
    gridLogo.onload = () => {
      this.setState({ loaded: true });
    };

    icon = new Image();
    icon.src = iconVal;
    icon.onload = () => {
      this.setState({ loaded: true });
    };

    let zoom = this.props.match.params.slug == `st-kilda` ? 10 : 7;
    map = new Image();
    map.src = `https://api.mapbox.com/styles/v1/neustudio/cjxx89ndo7xac1cocc7u1djni/static/url-${encodeURIComponent(
      markerURL
    )}(${storyLocation.location.lon},${storyLocation.location.lat})/${storyLocation.location.lon},${
      storyLocation.location.lat
    },${zoom},0,0/720x1280@2x?access_token=pk.eyJ1IjoibmV1c3R1ZGlvIiwiYSI6ImNqcnRhdmRydDF5NngzeXBzdTFkZ2h2NHAifQ.SmuHlMGPDvDf_fXM9Oh31Q`;

    map.onload = () => {
      this.setState({ loaded: true });
      console.log(map.src);
    };
  }

  render() {
    let { storyLocation } = this.props;

    if (
      !storyLocation ||
      !this.state.loaded ||
      !bigGrid.complete ||
      !pin.complete ||
      !logo.complete ||
      !gridLogo.complete ||
      !icon.complete ||
      !map.complete
    ) {
      return (
        <Wrapper>
          <span>loading</span>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <h1>Story</h1>
        <div style={{ position: 'relative', height: 1920 }}>
          <Stage width={1080} height={1920}>
            <Layer>
              <Img x={-100} y={-500} width={1404} height={2496} image={map} />

              <Img x={-0} y={-0} width={1080} height={1920} image={bigGrid} />

              <Rect
                fill={'#B6DDE0'}
                width={803}
                height={395}
                y={1157}
                x={(1080 - 803) / 2}
                cornerRadius={5}
              />

              <Img x={(1080 - 224) / 2} y={1100} width={224} height={110} image={logo} />
              <Text
                text={storyLocation.title}
                fontSize={63}
                fill={'white'}
                x={0}
                y={1245}
                width={1080}
                align={'center'}
                fontFamily="Montserrat"
                fontStyle={'Bold'}
              />

              <Img x={(1080 - 33) / 2} y={1340} width={33} height={48} image={pin} />

              <Text
                text={storyLocation.address}
                fontSize={30}
                fill={'white'}
                x={0}
                y={1405}
                width={1080}
                align={'center'}
                fontStyle={'Bold'}
              />

              <Rect
                fill={'rgba(0,0,0,0.05)'}
                width={270}
                height={115}
                y={1490 + 5}
                x={(1080 - 900) / 2 + 150 + 5}
                cornerRadius={5}
              />

              <Rect
                fill={'#fff'}
                width={270}
                height={115}
                y={1490}
                x={(1080 - 900) / 2 + 150}
                cornerRadius={5}
              />

              <Rect
                fill={'rgba(0,0,0,0.05)'}
                width={270}
                height={115}
                y={1490 + 5}
                x={1080 - (1080 - 900) / 2 - 425 + 5}
                cornerRadius={5}
              />

              <Rect
                fill={'#fff'}
                width={270}
                height={115}
                y={1490}
                x={1080 - (1080 - 900) / 2 - 425}
                cornerRadius={5}
              />

              <Text
                text={storyLocation.location.lat.toFixed(4)}
                fontSize={38}
                fill={'#4A4A4A'}
                y={1535}
                x={(1080 - 900) / 2 + 255}
                align={'center'}
                fontFamily="baskerville-display-pt"
              />

              <Text
                text={storyLocation.location.lon.toFixed(4)}
                fontSize={38}
                fill={'#4A4A4A'}
                y={1535}
                x={1080 - (1080 - 900) / 2 - 415}
                width={300}
                align={'center'}
                fontFamily="baskerville-display-pt"
              />

              <Img y={1532} x={280} width={40} height={40} image={icon} />
              <Img y={1532} x={600} width={40} height={40} image={icon} />

              <Img x={930} y={1770} width={103} height={91} image={gridLogo} />
            </Layer>
          </Stage>
        </div>
        <div style={{ position: 'relative', height: 1350 }}>
          <h1>Post</h1>
          <Stage width={1080} height={1350}>
            <Layer>
              <Img x={-100} y={-500 - 570} width={1404} height={2496} image={map} />

              <Img x={-0} y={-0} width={1080} height={1920} image={bigGrid} />

              <Rect
                fill={'#B6DDE0'}
                width={803}
                height={395}
                y={1157 - 570}
                x={(1080 - 803) / 2}
                cornerRadius={5}
              />

              <Img x={(1080 - 224) / 2} y={1100 - 570} width={224} height={110} image={logo} />
              <Text
                text={storyLocation.title}
                fontSize={63}
                fill={'white'}
                x={0}
                y={1245 - 570}
                width={1080}
                align={'center'}
                fontFamily="Montserrat"
                fontStyle={'Bold'}
              />

              <Img x={(1080 - 33) / 2} y={1340 - 570} width={33} height={48} image={pin} />

              <Text
                text={storyLocation.address}
                fontSize={30}
                fill={'white'}
                x={0}
                y={1405 - 570}
                width={1080}
                align={'center'}
                fontStyle={'Bold'}
              />

              <Rect
                fill={'rgba(0,0,0,0.05)'}
                width={270}
                height={115}
                y={1490 + 5 - 570}
                x={(1080 - 900) / 2 + 150 + 5}
                cornerRadius={5}
              />

              <Rect
                fill={'#fff'}
                width={270}
                height={115}
                y={1490 - 570}
                x={(1080 - 900) / 2 + 150}
                cornerRadius={5}
              />

              <Rect
                fill={'rgba(0,0,0,0.05)'}
                width={270}
                height={115}
                y={1490 + 5 - 570}
                x={1080 - (1080 - 900) / 2 - 425 + 5}
                cornerRadius={5}
              />

              <Rect
                fill={'#fff'}
                width={270}
                height={115}
                y={1490 - 570}
                x={1080 - (1080 - 900) / 2 - 425}
                cornerRadius={5}
              />

              <Text
                text={storyLocation.location.lat.toFixed(4)}
                fontSize={38}
                fill={'#4A4A4A'}
                y={1535 - 570}
                x={(1080 - 900) / 2 + 255}
                align={'center'}
                fontFamily="baskerville-display-pt"
              />

              <Text
                text={storyLocation.location.lon.toFixed(4)}
                fontSize={38}
                fill={'#4A4A4A'}
                y={1535 - 570}
                x={1080 - (1080 - 900) / 2 - 415}
                width={300}
                align={'center'}
                fontFamily="baskerville-display-pt"
              />

              <Img y={1532 - 570} x={280} width={40} height={40} image={icon} />
              <Img y={1532 - 570} x={600} width={40} height={40} image={icon} />

              <Img x={930} y={1770 - 570} width={103} height={91} image={gridLogo} />
            </Layer>
          </Stage>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 80px 30px;

  canvas,
  .konvajs-content {
    max-width: 100%;
    height: auto !important;
  }
`;

const mapStateToProps = state => {
  return {
    storyLocation: state.location.storyLocation
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadStoryLocation: slug => {
      dispatch(DataActions.loadStoryLocation(slug));
    }
  };
};

const Index = connect(
  mapStateToProps,
  mapDispatchToProps
)(Story);

export default withRouter(Index);
