import { SET_APP } from '../actions/action-types.js';

const initialState = {
  loaded: false,
  dataLoading: false,
  layered: false,
  canGoBack: false,
  bound: null,
  view: 'DISCOVER',
  seenSplash: window.localStorage.getItem('passed-gate') || false,
  userLocation: null,
  passedAgeGate: false
};

export default function(state = { ...initialState }, action) {
  switch (action.type) {
    case SET_APP:
      return {
        ...state,
        ...action.payload
      };
      break;
    default:
      return state;
  }
}
