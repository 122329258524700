import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

const LocationsLoader = props => {
  useEffect(() => {
    props.loadLocation(props.match.params.slug);
    // props.setApp({ layered: true });
  }, [props.match.params.slug]);
  return <span />;
};

export default withRouter(LocationsLoader);
