import React, { Component } from "react"
import { connect } from "react-redux"
import styled, { css } from "styled-components/macro"
import { withRouter, Link } from "react-router-dom"
import Fonts from "../../consts/Fonts"
import Colors from "../../consts/Colors"
import Devices from "../../consts/Devices"
import { trackPage } from "react-with-analytics"

import DataActions from "../../actions/dataActions"
import ListsActions from "../../actions/listsActions"

import Torn from "../../assets/torn@2x.png"
import TornV from "../../assets/torn-v@2x.png"
import ListsHead from "../../assets/lists-head.jpg"

import Hero from "../../components/Hero"
import Options from "../../components/Options"

class Lists extends Component {
  componentDidMount() {
    window.scroll({
      top: 0,
      left: 0,
    })
    this.props.loadLists()
    this.props.updateFilterTag(0)
    trackPage(window.location.pathname + window.location.search)
  }

  render() {
    let { lists, updateFilterTag, filterTag } = this.props
    return (
      <ListsWrapper>
        <Title>Top Lists</Title>
        <OptionsWrapper>
          <Options
            filterTagIndex={filterTag}
            options={["Latest", "Popular"]}
            updateFilterTag={(i) => {
              if (i == 1) {
                this.props.loadLists({
                  "fields.popular": true,
                })
              } else if (i == 0) {
                this.props.loadLists()
              } else if (i == 2) {
                if (window.navigator.geolocation) {
                  window.navigator.geolocation.getCurrentPosition((loc) => {
                    this.props.setApp({
                      userLocation: {
                        lat: loc.coords.latitude,
                        lng: loc.coords.longitude,
                      },
                    })
                    this.props.loadLists({
                      "fields.location[near]": `${loc.coords.latitude},${loc.coords.longitude}`,
                      limit: 10,
                    })
                  })
                } else {
                  alert("Geolocation is not supported by this browser.")
                }
              }
              updateFilterTag(i)
            }}
          />
        </OptionsWrapper>

        <ListsList>
          {lists.map((item, i) => {
            return (
              <ListItem key={i}>
                <Link to={`/list/${item.slug}`}>
                  <ItemImage image={item.image} />
                  <div>
                    <ItemTitle>{item.title}</ItemTitle>
                    <ItemSubTitle>{item.subTitle}</ItemSubTitle>
                  </div>
                  <ItemLink>→</ItemLink>
                </Link>
              </ListItem>
            )
          })}
        </ListsList>
      </ListsWrapper>
    )
  }
}

const HeroWrapper = styled(Hero)`
  @media ${Devices.laptop} {
    width: 64vw !important;
    > div {
      height: 100%;
    }
  }
`

const OuterWrapper = styled.div`
  @media ${Devices.laptop} {
    display: flex;
    flex-direction: row-reverse;
  }
`

const ItemLink = styled.div`
  background: ${Colors.blue};
  width: 44px;
  padding: 0 !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-size: 18px;

  @media ${Devices.laptop} {
    position: absolute;
    right: 0;
    height: 70px;
    width: 70px;
  }
`

const OptionsWrapper = styled.div`
  margin: 20px 0 35px;
`

const ListsList = styled.div`
  padding: 0 31px;
`

const ItemImage = styled.div`
  background: url(${(props) => props.image});
  background-size: cover !important;
  background-position: center !important;
  width: 65px;
  padding: 0 !important;
`

const ItemTitle = styled.h3`
  color: ${Colors.nearBlack};
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  margin-bottom: 5px;
`

const ItemSubTitle = styled.h4`
  color: ${Colors.grey};
  font-size: 10px;
  line-height: 12px;
  font-weight: 300;§
`

const ListItem = styled.div`
  a {
    background: #fff;
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
    border: 1px solid #f2f2f2;
    cursor: pointer;

    @media ${Devices.laptop} {
      justify-content: flex-start;
      position: relative;
    }
    > div {
      padding: 18px;

      &:nth-child(2) {
        width: 65%;
      }
    }
  }
`

const ListsWrapper = styled.div`
  margin: 19px 0;
  max-width: 100%;
  height: calc(66vh - 100px);
  position: relative;

  @media ${Devices.laptop} {
    padding: 90px 0px 0px;
    max-width: 565px;
    margin: 0 auto;
  }
`

const Title = styled.h2`
  ${Fonts.title}
  padding: 0 31px;
  @media ${Devices.laptop} {
    margin-top: 20px;
  }
`

const mapStateToProps = (state) => {
  return {
    filterTag: state.lists.filterTag,
    lists: state.lists.lists,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadLists: (filter) => {
      dispatch(DataActions.loadLists(filter))
    },
    updateFilterTag: (i) => {
      dispatch(ListsActions.updateFilterTag(i))
    },
    setApp: (e) => {
      dispatch({
        type: "SET_APP",
        payload: e,
      })
    },
  }
}

const Index = connect(mapStateToProps, mapDispatchToProps)(Lists)

export default withRouter(Index)
