import React, { Component } from "react"
import styled from "styled-components/macro"
import Colors from "../../consts/Colors"
import Devices from "../../consts/Devices"
import { connect } from "react-redux"
import AgeGate from "../agegate/Agegate"

import DataActions from "../../actions/dataActions"
import LocationActions from "../../actions/locationActions"

import Logo from "../../assets/splash-logo.png"
import HeadLogo from "../../assets/shackleton-logo@2x.png"
import Grid from "../../assets/grid-op.png"
import Bottle from "../../assets/bottle.png"
import BornAd from "../../assets/born-ad.png"

class index extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      selectedLocation: null,
      checkAge: 18,
      age: 1969,
      err: null,
    }
  }

  componentDidMount() {}

  componentDidUpdate() {
    if (this.props.locations.locations.length > 0 && !this.state.loaded) {
      let popularLocations = this.props.locations.locations.filter((loc) => {
        return loc.splash == true
      })

      this.setState({
        selectedLocation:
          popularLocations[Math.floor(Math.random() * popularLocations.length)],
        loaded: true,
      })
    }
  }
  render() {
    return (
      <Wrapper visible={this.props.visible}>
        <HeadLogoComp src={HeadLogo} />
        <Inner>
          <LogoComp>
            <h2>EXPLORE. </h2>
            <h2>DISCOVER.</h2>
            <h1>GO OFF GRID.</h1>
          </LogoComp>
          <Copy>
            <p>
              We’ve teamed up with the UK’s top adventurers to identify the best
              offline locations and staycations, free from 3G, 4G and 5G,
              throughout the nation.
            </p>
            <p>
              Born adventurous or simply looking to escape the everyday? Embrace
              the great outdoors and use the map to find your next adventure.
            </p>
            <AgeGate
              updateCheckAge={(checkAge) => {
                this.setState({ checkAge: checkAge })
              }}
              updateAge={(age) => {
                this.setState({ age: age })
              }}
            />
          </Copy>
          <Options>
            {/* <Option
              onClick={() => {
                if (window.navigator.geolocation) {
                  this.props.setApp({
                    seenSplash: true
                  });
                  this.props.loadGeo();
                  window.navigator.geolocation.getCurrentPosition(
                    loc => {
                      this.props.setApp({
                        userLocation: {
                          lat: loc.coords.latitude,
                          lng: loc.coords.longitude
                        }
                      });
                      this.props.loadLocations({
                        'fields.location[near]': `${loc.coords.latitude},${loc.coords.longitude}`,
                        limit: 10
                      });
                      this.props.updateFilterTag(2);
                      this.props.hide();
                    },
                    () => {
                      this.props.hide();
                    }
                  );
                } else {
                  alert('Geolocation is not supported by this browser.');
                }
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="12.672" height="19.034">
                <g data-name="Group 462">
                  <g fill="#fffffe" transform="translate(.001)" data-name="Group 463">
                    <path
                      data-name="Path 36"
                      d="M6.111.002A6.453 6.453 0 0 0 0 6.443a6.565 6.565 0 0 0 .527 2.081c.05.133.1.263.141.377a.46.46 0 0 0 .019.056L5.4 18.365a1.174 1.174 0 0 0 1.036.669h.009a1.175 1.175 0 0 0 1.036-.669L12.2 8.948a.461.461 0 0 0-.2-.612.445.445 0 0 0-.6.2l-4.727 9.4a.242.242 0 0 1-.226.141.254.254 0 0 1-.235-.141l-4.7-9.37c-.04-.111-.084-.23-.132-.358A5.982 5.982 0 0 1 .9 6.443a5.542 5.542 0 0 1 10.88-1.506.455.455 0 0 0 .556.32.465.465 0 0 0 .32-.565A6.471 6.471 0 0 0 6.441.002c-.111 0-.22-.005-.33 0z"
                      fill-rule="evenodd"
                    />
                    <circle
                      data-name="Ellipse 29"
                      cx="1.678"
                      cy="1.678"
                      r="1.678"
                      transform="translate(4.635 4.195)"
                    />
                  </g>
                </g>
              </svg>
              <span>Explore nearby me</span>
            </Option> */}
            <Option
              disabled={!this.state.age || !this.state.checkAge}
              onClick={() => {
                var dt = new Date()
                var year = dt.getFullYear()
                let error =
                  "YOU MUST BE OF LEGAL DRINKING AGE IN YOUR COUNTRY TO ENTER."
                if (!this.state.age || !this.state.checkAge) {
                  this.setState({ err: error })
                  alert(error)
                  return
                }

                if (
                  year - parseInt(this.state.age) <
                  parseInt(this.state.checkAge)
                ) {
                  this.setState({ err: error })
                  alert(error)
                  return
                } else {
                  window.localStorage.setItem("passed-gate", true)
                  this.props.hide()
                }
              }}
            >
              <span>Verify Age</span>
            </Option>
          </Options>
        </Inner>
        <Footer in={this.state.selectedLocation}>
          {this.state.selectedLocation && (
            <React.Fragment>
              <Location>
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="23.363" height="18.928">
                  <g data-name="Group 266">
                    <g transform="translate(6.663)" fill="#fff" data-name="Group 91">
                      <path
                        data-name="Path 36"
                        d="M5.423.002A5.725 5.725 0 0 0 0 5.717a5.825 5.825 0 0 0 .468 1.847c.044.118.09.234.125.334a.408.408 0 0 0 .017.05l4.178 8.347a1.042 1.042 0 0 0 .919.593h.008a1.043 1.043 0 0 0 .919-.593l4.194-8.356a.409.409 0 0 0-.184-.543.4.4 0 0 0-.535.175l-4.193 8.337a.215.215 0 0 1-.2.125.225.225 0 0 1-.209-.125l-4.17-8.311c-.035-.1-.074-.2-.117-.318A5.308 5.308 0 0 1 .8 5.717a4.917 4.917 0 0 1 9.651-1.337.4.4 0 0 0 .493.284.412.412 0 0 0 .284-.5A5.742 5.742 0 0 0 5.715.002c-.098 0-.195-.005-.292 0z"
                        fill-rule="evenodd"
                      />
                      <circle
                        data-name="Ellipse 29"
                        cx="1.489"
                        cy="1.489"
                        r="1.489"
                        transform="translate(4.113 3.722)"
                      />
                    </g>
                    <path
                      data-name="Path 99"
                      d="M8.177 14.128H5.332L.907 18.554H22.99c.021 0-3.32-4.426-3.32-4.426h-2.955"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-width=".75"
                    />
                  </g>
                </svg> */}
                {/* <div>
                  <span>
                    {this.state.selectedLocation.title}, {this.state.selectedLocation.address}
                  </span>
                </div> */}
              </Location>

              {/* <User>
                <img src={this.state.selectedLocation.instagramProfilePicUrl} />
                <div>
                  <span>
                    Recommended by <i>@{this.state.selectedLocation.instagramHandle}</i>
                  </span>
                </div>
              </User> */}
            </React.Fragment>
          )}
        </Footer>
        <Background
          locationImage={
            this.state.selectedLocation
              ? this.state.selectedLocation.images[0]
              : null
          }
        />
        <BottleComp src={Bottle}></BottleComp>
        <BornAdComp src={BornAd}></BornAdComp>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    locations: state.locations,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setApp: (e) => {
      dispatch({
        type: "SET_APP",
        payload: e,
      })
    },
    loadLocations: (filter) => {
      dispatch(DataActions.loadLocations(filter))
    },
    updateFilterTag: (i) => {
      dispatch(LocationActions.updateFilterTag(i))
    },
    loadGeo: (e) => {
      dispatch({
        type: "LOAD_LOCATIONS_PENDING",
      })
    },
  }
}

const Index = connect(mapStateToProps, mapDispatchToProps)(index)

export default Index

const HeadLogoComp = styled.img`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 135px;
  opacity: 0;

  animation: fadeInOp 3s;

  animation-fill-mode: forwards;
  will-change: transform;

  @keyframes fadeInOp {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* @media ${Devices.laptop} {
    display: none;
  } */
`

const Location = styled.div`
  display: flex;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  svg {
    position: relative;
    top: 0px;
    @media ${Devices.laptop} {
    }
  }
`

const User = styled.div`
  display: none;
  @media ${Devices.laptop} {
    display: flex;
  }
  img {
    width: 35px;
    height: 35px;
    border-radius: 9999em;
    margin-right: 15px;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  i {
    font-style: normal;
    font-weight: 600;
  }
`

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  -webkit-overflow-scroll;
  height: 100vh;
  z-index: 100;
  background: rgba(0, 0, 0, 1);
  transition: opacity 0.4s;
  /* ${(props) =>
    props.visible
      ? `
      opacity:1;
    `
      : `
      opacity:0;
      display: none;
    `} */

  ${(props) =>
    props.onLocation &&
    `
      display: none!important;
    `}
`

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: -webkit-fill-available;
  /* background: ${(props) =>
    props.locationImage
      ? `url(${Grid}), linear-gradient(0deg, rgba(0,0,0,0.9192051820728291) 0%, rgba(0,0,0,0.14609593837535018) 58%, rgba(255,255,255,0) 100%), url(${props.locationImage})`
      : ``}; */
  background: ${(props) =>
    props.locationImage
      ? `linear-gradient(0deg, rgba(0,0,0,0.9192051820728291) 0%, rgba(0,0,0,0.14609593837535018) 58%, rgba(255,255,255,0) 100%), url(${props.locationImage})`
      : ``};
  will-change: contents;
  background-position: center !important;
  background-repeat: no-repeat, no-repeat !important;
  background-size: cover, cover !important;
  z-index: -1;
  opacity: ${(props) => (props.locationImage ? 1 : 0)};
  filter: ${(props) => (props.locationImage ? `blur(0px)` : `blur(100px)`)};
  transition: filter 2s, opacity 2s;
  transition-delay: 0.4s;
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  height: 900px;
  animation: fadeIn 1s;

  animation-fill-mode: forwards;
  will-change: transform;

  @keyframes fadeIn {
    0% {
      transform: translateY(10%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  @media ${Devices.laptop} {
    width: 50vw;
    min-width: 600px;
    margin: 0px 120px;
    text-align: left;
    height: auto;
    h2,
    h1,
    p {
      text-align: left;
    }

    > div {
      text-align: left;
    }
  }
`
export const LogoComp = styled.div`
  margin: 0 auto 20px;
  text-align: center;
  h1,
  h2 {
    color: #fff;
    font-size: 30px;
    margin: 10px 0;
    font-weight: 900;

    @media ${Devices.laptop} {
      font-size: 50px;
      margin: 12px 0;
    }
  }

  h1 {
    color: #fff;
    /* -webkit-text-stroke: 1px #fff; */
    position: relative;
    /* left: 15px; */
    @media ${Devices.laptop} {
      /* -webkit-text-stroke: 1.5px #fff; */
      /* left: 35px; */
    }
  }
`

export const Copy = styled.div`
  max-width: 270px;
  margin: 0 auto;
  text-align: center;

  @media ${Devices.laptop} {
    max-width: 355px;
  }
  p {
    color: #fff;
    font-family: baskerville-display-pt, serif;
    font-size: 15px;
    line-height: 20px;
    @media ${Devices.laptop} {
      font-size: 17px;
      line-height: 23px;
    }
    margin-bottom: 25px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`
export const Options = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 200px;

  @media ${Devices.laptop} {
    flex-direction: row;
    margin: 25px auto 0;
    text-align: left;
    justify-content: flex-start;
    width: 355px;
    margin-bottom: 0px;

    &:hover {
      > div:first-of-type {
        &:after {
          transform: scale(0);
        }
      }
    }

    > div:first-of-type {
      &:after {
        transform: scaleX(1);
      }
    }
  }
`
export const Option = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  margin: 13px auto;
  cursor: pointer;
  position: relative;
  opacity: ${(props) => (props.disabled ? `0.5` : `1`)};
  width: 70px;
  border-bottom: 2px solid #fff;
  padding-bottom: 5px;

  @media ${Devices.laptop} {
    width: auto;
    padding-bottom: 0;
    border-width: 0;
    &:after {
      content: "";
      display: block;
      height: 2px;
      background: #fff;
      position: absolute;
      bottom: 0px;
      width: 100%;
      transform: scaleX(0);
    }

    margin-left: 0 !important;
    position: relative;
    margin: 0 21px;
    font-size: 16px;
    padding-bottom: 10px;

    &:after {
      transition: transform 0.3s;
    }

    &:hover {
      &:after {
        transform: scaleX(1) !important;
      }
    }
  }
  svg {
    margin-right: 10px;
    position: relative;
    top: 4px;

    @media ${Devices.laptop} {
      position: absolute;
      left: -20px;
      top: -3px;
      right: auto;
    }
  }
`
export const Footer = styled.div`
  position: fixed;
  will-change: transform;
  bottom: 20px;
  transform: ${(props) => (props.in ? "translateY(0px)" : "translateY(100px)")};
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  transition: transform 0.3s;
  will-change: transform;

  @media ${Devices.laptop} {
    justify-content: space-between;
    padding: 0 45px;
  }

  span {
    color: #fff;
    font-size: 12px;
  }

  svg {
    margin-right: 10px;
  }
`

export const Error = styled.div`
  font-size: 15px;
  color: #fff;
  max-width: 300px;
  margin: 0 auto;
`

const BottleComp = styled.img`
  position: fixed;
  z-index: -1;
  right: 0;
  bottom: 0;
  width: 50%;
  max-width: 550px;
`

const BornAdComp = styled.img`
  position: fixed;
  z-index: -1;
  bottom: 10px;
  left: 10px;
  width: 200px;
  @media ${Devices.laptop} {
    width: 200px;
    bottom: 20px;
    left: 20px;
  }
`
