import { LOAD_LIST_SUCCESS, LOAD_LISTS_PENDING } from '../actions/action-types.js';

const initialState = {
  notLoaded: true
};

export default function(state = { ...initialState }, action) {
  switch (action.type) {
    case LOAD_LISTS_PENDING:
      return initialState;
      break;
    case LOAD_LIST_SUCCESS:
      return action.payload;
      break;
    default:
      return state;
  }
}
