import React, { useState, useRef } from "react"
import styled from "styled-components/macro"
import Colors from "../../consts/Colors"
import Devices from "../../consts/Devices"

const Actions = ({ location, clearning }) => {
  const [coppied, setCoppied] = useState(false)
  const textAreaRef = useRef(null)

  function copyToClipboard(e) {
    textAreaRef.current.select()
    document.execCommand("copy")
    setCoppied(true)
    setTimeout(() => {
      setCoppied(false)
    }, 3000)
  }

  return (
    <Outer
      style={
        clearning
          ? { transform: `translateY(100%)` }
          : { transform: `translateY(0%)` }
      }
    >
      <Wrapper>
        {!location.isAccommodation ? (
          <Directions>
            <a
              href={`https://www.google.com/maps/dir/Current+Location/${location.location.lat},${location.location.lon}`}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="39.226"
                height="33.719"
              >
                <g data-name="Group 116" fill="#fffffe">
                  <path
                    data-name="Path 55"
                    d="M12.419 7.202a1.974 1.974 0 0 1 1.964 1.964v2.946h-1.31V9.166a.645.645 0 0 0-.655-.655h-2.291a.645.645 0 0 0-.655.655.655.655 0 0 0 .65.655h1.639v1.309h-1.634a1.9645 1.9645 0 1 1 0-3.929z"
                    fill-rule="evenodd"
                  />
                  <path
                    data-name="Path 56"
                    d="M39.226 28.154L0 32.402v1.317l39.226-4.247z"
                    fill-rule="evenodd"
                  />
                  <path
                    data-name="Path 57"
                    d="M18.595 8.185a4.586 4.586 0 0 1 2.43.7l4.123 2.578h3.742a1.974 1.974 0 0 1 1.964 1.964v.327a1.974 1.974 0 0 1-1.964 1.964h-4.438l-5.067-3.041.675-1.123 4.755 2.854h4.074a.645.645 0 0 0 .655-.655v-.327a.645.645 0 0 0-.655-.655h-4.117l-4.44-2.778a3.279 3.279 0 0 0-1.737-.5h-1.491a1.954 1.954 0 0 0-1.964 1.964v8.709a9.82 9.82 0 0 1-.7 3.647l-3.276 8.186-1.217-.488 3.274-8.182a8.513 8.513 0 0 0 .609-3.161v-8.709a3.284 3.284 0 0 1 3.274-3.274z"
                    fill-rule="evenodd"
                  />
                  <path
                    data-name="Path 58"
                    d="M17.442 22.968l1.189.55-3.928 8.516-1.189-.55z"
                    fill-rule="evenodd"
                  />
                  <path
                    data-name="Path 59"
                    d="M17.743 21.142l2.637 2.64a3.272 3.272 0 0 1 .908 2.883l-.745 4.222-1.289-.228.744-4.223a1.962 1.962 0 0 0-.545-1.729l-2.637-2.637z"
                    fill-rule="evenodd"
                  />
                  <path
                    data-name="Path 60"
                    d="M21.49 12.768v5.19a1.966 1.966 0 0 0 .524 1.338l2.6 2.8a3.274 3.274 0 0 1 .785 2.985l-1.309 5.514-1.274-.3 1.312-5.512a1.965 1.965 0 0 0-.471-1.793l-2.6-2.8a3.275 3.275 0 0 1-.875-2.228v-5.19z"
                    fill-rule="evenodd"
                  />
                  <path
                    data-name="Path 61"
                    d="M30.098 16.697v13.1h-1.309v-13.1z"
                    fill-rule="evenodd"
                  />
                  <path
                    data-name="Path 62"
                    d="M30.098 9.494v1.31h-1.309v-1.31z"
                    fill-rule="evenodd"
                  />
                  <path
                    data-name="Path 63"
                    d="M9.886 10.129l1.11.693-1.35 2.159a1.24 1.24 0 0 0-.187.657v6.333a.645.645 0 0 0 .655.655h3.6v1.309h-3.6a1.974 1.974 0 0 1-1.964-1.964v-6.333a2.549 2.549 0 0 1 .386-1.35z"
                    fill-rule="evenodd"
                  />
                  <path
                    data-name="Path 64"
                    d="M17.278 0a3.6 3.6 0 1 1-3.6 3.6 3.611 3.611 0 0 1 3.6-3.6zm0 1.31a2.292 2.292 0 1 0 2.292 2.292 2.282 2.282 0 0 0-2.292-2.295z"
                  />
                </g>
              </svg>
              <span>Get directions & Go</span>
            </a>
          </Directions>
        ) : (
          <Directions>
            <a href={location.website} target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36.226"
                height="31.719"
              >
                <g data-name="Group 538">
                  <g data-name="Group 532">
                    <path
                      data-name="Path 262"
                      d="M14.47 8.01h8.37a1.09 1.09 0 001.09-1.08 1.06 1.06 0 00-1.1-1.1h-8.36a1.09 1.09 0 000 2.18z"
                      fill="#fff"
                    />
                  </g>
                  <g data-name="Group 533">
                    <path
                      data-name="Path 263"
                      d="M14.47 13h8.37a1.09 1.09 0 100-2.18h-8.37a1.09 1.09 0 100 2.18z"
                      fill="#fff"
                    />
                  </g>
                  <g data-name="Group 534">
                    <path
                      data-name="Path 264"
                      d="M18.01 15.84h-3.54a1.09 1.09 0 100 2.17h3.54a1.09 1.09 0 100-2.18z"
                      fill="#fff"
                    />
                  </g>
                  <g data-name="Group 535">
                    <path
                      data-name="Path 265"
                      d="M29.53 14.32V1.09A1.09 1.09 0 0028.44 0H1.1A1.09 1.09 0 000 1.09v21.63a1.09 1.09 0 001.09 1.1h18.95a8.5 8.5 0 109.5-9.5zM2.18 2.18h25.21v12.14a8.46 8.46 0 00-7.31 7.31H2.18V2.18zM28.44 29.1a6.34 6.34 0 116.34-6.34 6.34 6.34 0 01-6.34 6.34z"
                      fill="#fff"
                    />
                  </g>
                  <g data-name="Group 536">
                    <path
                      data-name="Path 266"
                      d="M31.24 21.67h-1.7V19.3a1.09 1.09 0 00-1.1-1.1 1.06 1.06 0 00-1.09 1.1v3.46a1.09 1.09 0 001.1 1.09h2.8a1.09 1.09 0 001.08-1.09 1.06 1.06 0 00-1.09-1.09z"
                      fill="#fff"
                    />
                  </g>
                  <g data-name="Group 537">
                    <path
                      data-name="Path 267"
                      d="M10.2 15.06a.81.81 0 00-.86-.55l-1.4.16a9.13 9.13 0 01-.43-2.76 8.83 8.83 0 01.43-2.8l1.4.15a.81.81 0 00.85-.54l.82-2.57a.78.78 0 00-.58-1.01l-1.8-.35a1.21 1.21 0 00-1.12.39 7.2 7.2 0 00-.86 1.12 11.22 11.22 0 00-1.4 5.65 11.33 11.33 0 001.4 5.64 5.54 5.54 0 00.86 1.09 1.25 1.25 0 001.13.39l1.79-.4a.78.78 0 00.58-1z"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
              <span>Check availability</span>
            </a>
          </Directions>
        )}
        <ShareBtn
          coppied={coppied}
          onClick={() => {
            if (navigator.share) {
              navigator
                .share({
                  title: `${location.title} X Shackleton Off Grid`,
                  text: `Let's Go Off Grid to ${location.title} - ${location.address}.`,
                  url: `https://offgrid.theshackletonwhisky.com/location/${location.slug}`,
                })
                .then(() => console.log("Successful share"))
                .catch((error) => console.log("Error sharing", error))
            } else {
              copyToClipboard()
              setCoppied(true)
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34.349"
            height="31.805"
          >
            <path
              data-name="Path 87"
              d="M17.174 13.994h-7.4l-3.7 2.436a.6.6 0 0 1-.35.108.636.636 0 0 1-.35-1.164l3.861-2.544a.636.636 0 0 1 .35-.108h7.589a1.908 1.908 0 0 0 1.908-1.908V3.18a1.908 1.908 0 0 0-1.908-1.908H3.18A1.908 1.908 0 0 0 1.272 3.18v7.633a1.908 1.908 0 0 0 1.908 1.909h2.544a.636.636 0 1 1 0 1.272H3.18A3.18 3.18 0 0 1 0 10.814V3.18A3.18 3.18 0 0 1 3.18 0h13.994a3.18 3.18 0 0 1 3.18 3.18v7.633a3.18 3.18 0 0 1-3.18 3.181zm-7-5.725A1.272 1.272 0 1 0 8.905 7a1.272 1.272 0 0 0 1.272 1.269zM3.817 7a1.272 1.272 0 1 0 1.272-1.272A1.272 1.272 0 0 0 3.817 7zm12.722 0a1.272 1.272 0 1 0-1.272 1.272A1.272 1.272 0 0 0 16.538 7zm14.63 8.269H17.174a3.18 3.18 0 0 0-3.18 3.18v7.631a3.18 3.18 0 0 0 3.18 3.18h7.4l3.7 2.436a.6.6 0 0 0 .35.108.636.636 0 0 0 .35-1.164l-3.861-2.54a.636.636 0 0 0-.35-.108h-7.589a1.908 1.908 0 0 1-1.908-1.908v-7.637a1.908 1.908 0 0 1 1.908-1.908h13.994a1.908 1.908 0 0 1 1.908 1.908v7.633a1.908 1.908 0 0 1-1.908 1.908h-2.544a.636.636 0 1 0 0 1.272h2.544a3.18 3.18 0 0 0 3.18-3.18v-7.633a3.18 3.18 0 0 0-3.18-3.181zm-8.269 7a1.272 1.272 0 1 0 1.272-1.272 1.272 1.272 0 0 0-1.272 1.266zm-5.089 0a1.272 1.272 0 1 0 1.272-1.272 1.272 1.272 0 0 0-1.272 1.266zm11.45 1.272a1.272 1.272 0 1 0-1.272-1.272 1.272 1.272 0 0 0 1.271 1.266z"
              fill="#1a1919"
            />
          </svg>
          {navigator.share && <span>Share Destination</span>}

          {!navigator.share && (
            <span>{coppied ? "Copied to Clipboard" : "Share Destination"}</span>
          )}
        </ShareBtn>
        <textarea
          ref={textAreaRef}
          value={`Let's Go Off Grid to ${location.title} - ${location.address}. https://offgrid.theshackletonwhisky.com/location/${location.slug}`}
        />
      </Wrapper>
    </Outer>
  )
}

const Outer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  transition: all 0.9s;
  animation: fadeIn 0.4s;
  will-change: transform;
  @media ${Devices.laptop} {
    bottom: 0;
    width: 40% !important;
    right: 0;
    left: auto;
  }

  @keyframes fadeIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
`

const Wrapper = styled.div`
  background: black;
  display: flex;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  box-shadow: rgba(0, 0, 0, 0.06) 0px -3px 6px;

  > div {
    cursor: pointer;
  }

  textarea {
    position: fixed;
    left: -99999999%;
  }

  @media ${Devices.laptop} {
    width: 100%;
    right: 0;
    left: auto;
  }
`

const Directions = styled.div`
  background: #1a1919;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 10px 0;
  svg {
    display: block;
    margin: 0 auto 13px;
  }
  span {
    font-size: 14px;
    color: #fff;
    font-weight: 600;
  }
`

const ShareBtn = styled.div`
  background: ${(props) => (props.coppied ? "#B6DDE0" : "#fffffe")};
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 10px 0;
  transition: background 0.3s;
  svg {
    display: block;
    margin: 0 auto 13px;
  }
  span {
    font-size: 14px;
    color: #1a1919;
    font-weight: 600;
  }
  ${(props) =>
    props.coppied &&
    `
    span{
      color: #fff;
    }

    svg {
      path{
        fill: #fff;
      }
    }
  `}
`

export default Actions
