import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components/macro"
import Colors from "../../consts/Colors"
import Devices from "../../consts/Devices"
import Slider from "react-slick"
import { withRouter } from "react-router-dom"
import Skeleton from "react-loading-skeleton"

import Slide from "../Slide"

const Carousel = ({
  locations,
  updateCurrentLocation,
  currentLocation,
  className,
  setApp,
  loading,
  onClick,
  locationsLoading,
  getWeather,
  currentIndex,
  onLocation,
}) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    easing: "ease-out",
  }

  let sliderComp

  useEffect(() => {
    if (sliderComp) {
      sliderComp.slickGoTo(currentIndex)
    }
  })

  if (loading || !locations) {
    return <span>Loading</span>
  }
  if (locationsLoading) {
    return (
      <Wrapper className={className} locationsLoading={locationsLoading}>
        <Skeleton />
        <Skeleton />
      </Wrapper>
    )
  }

  return (
    <Wrapper className={className}>
      <Slider
        ref={(slider) => {
          sliderComp = slider
        }}
        {...settings}
        afterChange={(i) => {
          if (updateCurrentLocation) {
            setTimeout(() => {
              updateCurrentLocation(i)
            }, 300)
          }
        }}
      >
        {locations.map((location, i) => {
          return (
            <Slide
              key={i}
              onLocation={onLocation}
              index={i}
              geoLocation={location}
              active={i == currentLocation}
              getWeather={getWeather}
              onClick={(rect) => {
                // setApp({ canGoBack: true, bound: rect });
                setApp({ canGoBack: true, layered: true })
                if (onClick) {
                  onClick()
                }
              }}
            />
          )
        })}
      </Slider>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;

  .slick-list {
    padding: 0 45px 0 10px !important;
  }

  @media ${Devices.laptop} {
    > div {
      height: 300px;
    }
  }

  ${(props) =>
    props.locationsLoading
      ? `
        display: flex;
        padding: 10px 0px;
        padding-left: 10px;
        overflow: hidden;
        width: 100%;
        > span > span{
          display: block;
          height: 390px;
          width: 65.34vw;
          margin: 0 10px 0 20px;
        }
        `
      : ``}
`

export default withRouter(Carousel)
