import React, { Component } from "react"
import { connect } from "react-redux"
import styled, { css } from "styled-components/macro"
import { withRouter, Link } from "react-router-dom"
import Fonts from "../../consts/Fonts"
import Colors from "../../consts/Colors"
import Devices from "../../consts/Devices"
import { trackPage } from "react-with-analytics"

import DataActions from "../../actions/dataActions"
import LocationActions from "../../actions/locationActions"

import Map from "../../components/Map"
import Carousel from "../../components/Carousel"
import Options from "../../components/Options"
import Slide from "../../components/Slide"
import CarouselDots from "../../components/CarouselDots"
import Splash from "../../components/Splash"

import Torn from "../../assets/torn@2x.png"
import TornV from "../../assets/torn-v@2x.png"
import { throwStatement } from "@babel/types"

class Discover extends Component {
  constructor(props) {
    super(props)
    this.state = { showSplash: window.location.pathname == "/" }
  }

  componentDidMount() {
    window.scroll({
      top: 0,
      left: 0,
    })
    this.props.updateFilterTag(0)
    trackPage(window.location.pathname + window.location.search)
  }

  render() {
    let {
      locations,
      currentLocationIndex,
      updateFilterTag,
      filterTag,
      dataLoading,
      getWeather,
      location,
    } = this.props

    return (
      <Wrapper>
        {/* {!this.props.seenSplash && (
          <Splash
            hide={() => {
              this.setState({ showSplash: false });
              this.props.setApp({
                seenSplash: true
              });
            }}
            visible={this.state.showSplash}
            onLocation={location.data}
          />
        )} */}
        <Header>
          <Title>Discover</Title>
          <CarouselDotsComp
            currentIndex={currentLocationIndex}
            items={locations}
            color={"#1A1919"}
          />
        </Header>

        <OptionsComp
          filterTagIndex={filterTag}
          options={["Latest", "Popular", "Nearby Me", "Stay"]}
          updateFilterTag={(i) => {
            if (i == 1) {
              this.props.loadLocations({
                "fields.popular": true,
              })
            } else if (i == 0) {
              this.props.loadLocations()
            } else if (i == 2) {
              if (window.navigator.geolocation) {
                this.props.loadGeo()
                window.navigator.geolocation.getCurrentPosition((loc) => {
                  this.props.setApp({
                    userLocation: {
                      lat: loc.coords.latitude,
                      lng: loc.coords.longitude,
                    },
                  })
                  this.props.loadLocations({
                    "fields.location[near]": `${loc.coords.latitude},${loc.coords.longitude}`,
                    limit: 10,
                  })
                })
              } else {
                alert("Geolocation is not supported by this browser.")
              }
            } else if (i == 3) {
              this.props.loadLocations({
                "fields.isAccommodation": true,
              })
            }
            updateFilterTag(i)
          }}
        />

        <CarouselComp
          locations={this.props.locations}
          updateCurrentLocation={this.props.updateCurrentLocation}
          currentLocation={currentLocationIndex}
          locationsLoading={this.props.locationsLoading}
          setApp={(e) => {
            this.props.setApp(e)
          }}
          currentIndex={this.props.currentIndex}
          loading={dataLoading}
          getWeather={getWeather}
        />

        <DesktopCards>
          {locations.map((location, i) => {
            return (
              <DesktopSlide
                key={i}
                index={i}
                geoLocation={location}
                active={i == currentLocationIndex}
                locationsLoading={this.props.locationsLoading}
                filterTag={this.props.filterTag}
                onMouseEnter={() => {
                  this.props.updateCurrentLocation(i)
                }}
              />
            )
          })}
        </DesktopCards>
      </Wrapper>
    )
  }
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 31px;
`

const CarouselDotsComp = styled(CarouselDots)`
  margin-top: 20px;
`

const DesktopSlide = styled(Slide)`
  height: 470px;
  width: 320px;
  a {
    width: 100%;
  }
  a > div {
    margin: 0 !important;
    width: 100%;
  }
  cursor: pointer;
  padding: 10px 10px;

  span span {
    height: 100%;
    width: 258px;
    margin-right: 22px;
  }
`

const DesktopCards = styled.div`
  display: none;
  @media ${Devices.laptop} {
    display: flex;
    flex-wrap: wrap;
    width: 684px;
    position: relative;
    z-index: 2;
    padding-left: 25px;
    /* padding-left: 26px; */
  }
`

const OptionsComp = styled(Options)`
  @media ${Devices.laptop} {
    padding: 0 20px 0 37px;
    margin-bottom: 25px;
  }
`

const CarouselComp = styled(Carousel)`
  @media ${Devices.laptop} {
    display: none;
  }
`

const Wrapper = styled.div`
  @media ${Devices.laptop} {
    padding: 90px 0px 0px;
  }
`

// `;

const DiscoverWrapper = styled.div`
  margin: 19px 0;
  max-width: 100%;
  position: relative;
  /* @media ${Devices.laptop} {
    height: auto;
    width: 100%;
    margin: 0;
    padding: 131px 0 100px;
  } */
`

const Title = styled.h2`
  ${Fonts.title}
  margin-top: 20px;
  /* @media ${Devices.laptop} {
    padding: 0 20px 0 37px;
  } */
`

const mapStateToProps = (state) => {
  return {
    seenSplash: state.app.seenSplash,
    locations: state.locations.locations,
    location: state.location,
    loaded: state.app.loaded,
    currentLocationIndex: state.locations.currentIndex,
    filterTag: state.locations.filterTag,
    dataLoading: state.app.dataLoading,
    locationsLoading: state.locations.locationsLoading,
    currentIndex: state.locations.currentIndex,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadLocations: (filter) => {
      dispatch(DataActions.loadLocations(filter))
    },
    updateCurrentLocation: (i) => {
      dispatch(LocationActions.updateCurrentLocation(i))
    },
    updateFilterTag: (i) => {
      dispatch(LocationActions.updateFilterTag(i))
    },
    getWeather: (geoLocation) => {
      dispatch(LocationActions.getWeather(geoLocation))
    },
    setApp: (e) => {
      dispatch({
        type: "SET_APP",
        payload: e,
      })
    },

    loadGeo: (e) => {
      dispatch({
        type: "LOAD_LOCATIONS_PENDING",
      })
    },
  }
}

const Index = connect(mapStateToProps, mapDispatchToProps)(Discover)

export default React.memo(withRouter(Index))
