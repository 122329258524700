import { UPDATE_FILTER_TAG } from "../actions/action-types.js";

const Actions = {
  updateFilterTag: i => async dispatch => {
    dispatch({
      type: UPDATE_FILTER_TAG,
      payload: i
    });
  }
};

export default Actions;
