import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

const ListsLoader = props => {
  useEffect(() => {
    props.loadLists();
    props.setApp({ view: "LISTS" });
    // props.setApp({ layered: true });
  }, [props.match.params.slug]);
  return <span />;
};

export default withRouter(ListsLoader);
