import {
  LOAD_LOCATIONS_PENDING,
  LOAD_LOCATIONS_SUCCESS,
  LOAD_RELATED_LOCATIONS_SUCCESS,
  UPDATE_CURRENT_LOCATION,
  UPDATE_FILTER_TAG,
  UPDATE_WEATHER
} from "../actions/action-types.js";

const initialState = {
  locations: [],
  relatedLocations: [],
  currentIndex: 0,
  filterTag: null,
  locationsLoading: false
};

export default function(state = { ...initialState }, action) {
  switch (action.type) {
    case LOAD_LOCATIONS_PENDING:
      return {
        ...state,
        locationsLoading: true
      };
      break;
    case LOAD_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.payload,
        locationsLoading: false,
        currentIndex: 0
      };
      break;
    case UPDATE_CURRENT_LOCATION:
      return {
        ...state,
        currentIndex: action.payload
      };
      break;
    case UPDATE_FILTER_TAG:
      return {
        ...state,
        filterTag: action.payload
      };
      break;
    case UPDATE_WEATHER:
      let locations = state.locations;
      return {
        ...state
      };
    case LOAD_RELATED_LOCATIONS_SUCCESS:
      return {
        ...state,
        relatedLocations: action.payload
      };
      break;
    default:
      return state;
  }
}
