import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components/macro"
import Colors from "../../consts/Colors"
import Slider from "react-slick"
import Devices from "../../consts/Devices"
import { withRouter } from "react-router-dom"

const ImageCarousel = ({ images, size, afterChange, portrait }) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Wrapper>
      <Slider
        {...settings}
        afterChange={(i) => {
          if (afterChange) {
            afterChange(i)
          }
        }}
      >
        {images.map((image, i) => {
          return <Slide key={i} size={size} image={image} portrait={portrait} />
        })}
      </Slider>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
`

const Slide = styled.div`
  height: ${(props) => (props.size == "large" ? "450px" : "200px")};
  background: ${(props) => `url(${props.image}?w=900)`};
  background-size: cover !important;
  background-position: center !important;
  @media ${Devices.laptop} {
    ${(props) =>
      props.portrait &&
      `
    min-height: 740px;
    > div {
      min-height: 740px;
    }
  `}
  }
`

export default withRouter(ImageCarousel)
