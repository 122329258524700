import React from "react"
import styled, { css } from "styled-components/macro"
import Colors from "../../consts/Colors"
import Devices from "../../consts/Devices"
import Media from "react-media"

const Host = ({ location }) => {
  console.log(location.website)
  return (
    <Wrapper>
      <a href={location.website} target="_blank">
        <Handle>
          <h4>Check availability</h4>
        </Handle>
      </a>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  a {
    display: flex;
    flex-direction: row;
    /* margin: 0 30px; */
    margin-bottom: 31px;
    @media ${Devices.laptop} {
      margin: 0 0 31px 0;
    }
  }
`

const Profile = styled.div`
  width: 41px;
  height: 41px;
  overflow: hidden;
  border-radius: 9999em;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    width: 100%;
  }
`

const Handle = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 15px;
    line-height: 20px;
    font-family: baskerville-display-pt, serif;
    margin-bottom: 1px;

    @media ${Devices.laptop} {
      font-size: 17px;
      line-height: 19px;
    }
  }

  h4 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #5ec9f0;
  }
`

export default Host
