import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import Fonts from '../../consts/Fonts';
import Colors from '../../consts/Colors';
import Devices from '../../consts/Devices';
import { createClient } from 'contentful';
import { trackPage } from 'react-with-analytics';

import DataActions from '../../actions/dataActions';

import TextInput from './textInput.js';

import Tick from '../../assets/tick@2x.png';
import Cross from '../../assets/cross@2x.png';
import Loader from '../../assets/ajax-loader.gif';

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instagramHandle: '',
      location: '',
      area: '',
      description: '',
      images: [],
      icons: [],
      sent: false,
      validated: false,
      uploading: false
    };

    this.uploadWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: 'dikez8p3m',
        uploadPreset: 'zdcmzsxi'
      },
      (error, result) => {
        if (result.event === 'close') {
          this.props.setApp({
            locked: false
          });
        }
        if (!error && result && result.event === 'success') {
          this.setState({
            images: [...this.state.images, { url: result.info.secure_url }]
          });
          this.refs.input.value = '';
        }
      }
    );
  }

  async componentDidMount() {
    window.scroll({
      top: 0,
      left: 0
    });
    trackPage(window.location.pathname + window.location.search);
    let client = createClient({
      space: '7s9yafw5qome',
      accessToken: 'IqvOWMwT-gjrsJXgUbWqgGYL9r5X_CQHYxluZikVWh4',
      host: 'cdn.contentful.com'
    });

    let request = await client.getEntries({
      content_type: 'icon'
    });
    this.setState({
      icons: request.items.map(item => {
        return {
          image: item.fields.icon.fields.file.url,
          title: item.fields.title
        };
      })
    });

    this.props.setApp({
      layered: false
    });
  }

  _submit() {
    this.setState({ validated: false });
    let expect = this.state.icons.map(icon => {
      if (icon.active) {
        return icon.title;
      }
    });
    expect = expect.filter(function(e) {
      return e;
    });
    if (
      this.state.instagramHandle.length > 0 &&
      this.state.location.length > 0 &&
      this.state.area.length > 0 &&
      this.state.images.length > 0 &&
      this.state.description.length > 0
    ) {
      axios.post('https://offgrid.theshackletonwhisky.com/.netlify/functions/post', {
        Instagram: this.state.instagramHandle,
        Location: this.state.location,
        Area: this.state.area,
        Images: this.state.images,
        Description: this.state.description,
        Expect: expect
      });

      this.setState({ sent: true });
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.setState({ validated: true });
    }
  }

  _uploadFiled(file) {
    this.setState({ uploading: true });
    var url = `https://api.cloudinary.com/v1_1/dikez8p3m/upload`;
    var xhr = new XMLHttpRequest();
    var fd = new FormData();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    // // Reset the upload progress bar
    // document.getElementById("progress").style.width = 0;
    // Update progress (can be used to show progress indicator)
    xhr.upload.addEventListener('progress', function(e) {
      var progress = Math.round((e.loaded * 100.0) / e.total);
      // document.getElementById("progress").style.width = progress + "%";
      console.log(`fileuploadprogress data.loaded: ${e.loaded},
      data.total: ${e.total}`);
    });
    xhr.onreadystatechange = e => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var response = JSON.parse(xhr.responseText);
        var url = response.secure_url;
        this.setState({
          images: [...this.state.images, { url: url }],
          uploading: false
        });
      }
    };
    fd.append('upload_preset', 'zdcmzsxi');
    fd.append('file', file);
    xhr.send(fd);
  }

  render() {
    let {} = this.props;
    return (
      <AddWrapper>
        <Title>Add Location</Title>
        {this.state.sent && (
          <Thanks>
            <p>Thanks for your off grid submission.</p>
            <p>If selected, we will be in contact soon.</p>
          </Thanks>
        )}
        {!this.state.sent && (
          <Inputs>
            <TextInput
              label={'Your Instagram Handle'}
              value={this.state.instagramHandle}
              valid={this.state.instagramHandle.length > 0}
              update={e => {
                this.setState({ instagramHandle: e.target.value });
              }}
              validated={this.state.validated}
            />

            <TextInput
              label={'Name of Off Grid Location'}
              value={this.state.location}
              valid={this.state.location.length > 0}
              update={e => {
                this.setState({ location: e.target.value });
              }}
              validated={this.state.validated}
            />

            <TextInput
              label={'Nearest Town / Area'}
              value={this.state.area}
              valid={this.state.area.length > 0}
              update={e => {
                this.setState({ area: e.target.value });
              }}
              validated={this.state.validated}
            />

            <ImageUpload>
              <UploadTitle>
                <h3>{this.state.images.length} Images uploaded</h3>
                <svg xmlns="http://www.w3.org/2000/svg" width="18.862" height="18.868">
                  <path
                    data-name="Path 91"
                    d="M17.038 16.18a1.622 1.622 0 0 1-1.6 1.624H2.662a1.622 1.622 0 0 1-1.6-1.624V3.401a1.576 1.576 0 0 1 1.6-1.567h7.454V.766H2.662A2.643 2.643 0 0 0 0 3.402V16.18a2.687 2.687 0 0 0 2.662 2.688H15.44a2.688 2.688 0 0 0 2.662-2.688V8.752h-1.065v7.428z"
                  />
                  <path
                    data-name="Path 92"
                    d="M18.238.604a2.18 2.18 0 0 0-3.012 0L8.083 7.747a.532.532 0 0 0-.14.247l-.752 3.011a.533.533 0 0 0 .516.662.518.518 0 0 0 .129-.016l3.012-.753a.527.527 0 0 0 .247-.14l7.143-7.143a2.128 2.128 0 0 0 0-3.011zm-7.791 9.3l-2.008.5.5-2.008 5.909-5.909 1.506 1.506zm7.038-7.039l-.376.376-1.506-1.507.376-.376a1.091 1.091 0 0 1 1.506 0 1.065 1.065 0 0 1 0 1.506z"
                  />
                </svg>
              </UploadTitle>

              <Uploaded>
                {this.state.images.map((image, i) => {
                  return <Image style={{ background: `url(${image.url})` }} key={i} />;
                })}
                <AddPhoto
                  onClick={() => {
                    if (window.innerWidth > 900) {
                      this.props.setApp({
                        locked: true
                      });
                      this.uploadWidget.open();
                    } else {
                      this.refs.input.click();
                    }
                  }}
                >
                  <h4>+</h4>
                  <span>Add Photo</span>
                </AddPhoto>
              </Uploaded>
              {this.state.uploading && <img src={Loader} />}
              <HiddenInput
                type="file"
                ref="input"
                multiple
                accept="image/*"
                onChange={e => {
                  for (var i = 0; i < e.target.files.length; i++) {
                    this._uploadFiled(e.target.files[i]);
                  }
                }}
              />
              <TickWrapper valid={this.state.images.length > 0} src={Tick} />
              <CrossWrapper
                valid={this.state.images.length < 1 && this.state.validated}
                src={Cross}
              />
            </ImageUpload>

            <TextInput
              label={'Can you describe what made it unique?'}
              value={this.state.description}
              validated={this.state.validated}
              valid={this.state.description.length > 0}
              placeholder={''}
              update={e => {
                this.setState({ description: e.target.value });
              }}
              type="textarea"
            />

            <Expect>
              <h3>What can I expect?</h3>
              <Icons>
                {this.state.icons.map((icon, i) => {
                  return (
                    <Icon
                      active={icon.active}
                      key={i}
                      onClick={() => {
                        this.state.icons[i].active = !this.state.icons[i].active;
                        this.setState({
                          icons: this.state.icons
                        });
                      }}
                    >
                      <img src={icon.image} />
                      <span>{icon.title}</span>
                    </Icon>
                  );
                })}
              </Icons>
            </Expect>
          </Inputs>
        )}
        {!this.state.sent && (
          <Send
            onClick={() => {
              this._submit();
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="36.012" height="35.551">
              <g fill="#fffffe" stroke="#000" stroke-width=".3">
                <path
                  data-name="Path 96"
                  d="M23.91703587 15.71836426l-1.43649784-.66984994.85073056-1.82439758a1.93 1.93 0 0 0-.97893391-2.50766393 1.819 1.819 0 0 0-1.56322726-.00292217l-3.96392998 1.69784573-2.23948654-1.04428973a.968.968 0 0 0-1.28640042.46821146l-2.45456686 5.26383563a.968.968 0 0 0 .46821146 1.28640041l8.72139983 4.06685554a1.855 1.855 0 0 0 2.1759111-.4638824l2.61441592-3.0156423a1.924 1.924 0 0 0 .477124-1.67863357 2.353 2.353 0 0 0-1.38475006-1.57586715zM13.8825333 17.44651444l-.8736807-.407404 1.63595528-3.50831745.8736807.407404zm6.90606533 3.22035116l-5.14782823-2.40047173 1.8882584-4.0493832 3.99039124-1.69543703-1.20530728 2.5847898a.968.968 0 0 0 .46821146 1.28640042L23.096128 17.4717083c.21570125.10058314.3000074.20609842.26225759.23263051z"
                />
                <path
                  data-name="Path 97"
                  d="M33.18264845 10.42636193L13.87829258 1.42459296a2.907 2.907 0 0 0-3.85388554 1.40269962L3.47805017 16.8660002a2.907 2.907 0 0 0 1.40269962 3.85388555l.87730594.40909447-1.63637791 3.50922375A.968.968 0 0 0 5.3307337 25.9567065l6.05458708-2.20368948 12.79978487 5.96863771a2.907 2.907 0 0 0 3.85388555-1.40269962l6.54635687-14.03870762a2.907 2.907 0 0 0-1.40269962-3.85388555zm-6.8946439 17.07609469a.969.969 0 0 1-1.28640041.46821146L11.84110876 21.8338283a.966.966 0 0 0-.73993811-.03167948l-4.23528732 1.54151851 1.05654565-2.26576946a.968.968 0 0 0-.46821146-1.28640042l-1.7582371-.81987943a.969.969 0 0 1-.46821146-1.28640041l6.54762473-14.04142655a.969.969 0 0 1 1.28470994-.46458623l19.30435586 9.00176898a.969.969 0 0 1 .46821146 1.28640042z"
                />
              </g>
            </svg>
            <span>Submit for Review</span>
          </Send>
        )}
      </AddWrapper>
    );
  }
}

const HiddenInput = styled.input`
  /* position: fixed;
  left: 999999px; */
  display: block;
  width: 100%;
  margin: 15px auto 15px;
  width: 200px;
  /* position: relative;
  left: 50%;
  transform: translateX(-35%); */
  @media ${Devices.laptop} {
    display: none;
  }
`;

const Thanks = styled.div`
  padding: 15px 31px;

  p {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 5px;
  }
`;

const TickWrapper = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  opacity: ${props => (props.valid ? 1 : 0)};
  transition: all 0.3s;
  width: 35px;
`;

const CrossWrapper = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  opacity: ${props => (props.valid ? 1 : 0)};
  transition: all 0.3s;
  width: 35px;
`;

const Send = styled.div`
  background: #1a1919;
  text-align: center;
  cursor: pointer;
  padding: 30px 0;

  @media ${Devices.laptop} {
    margin: 0 36px 15px;
    padding: 10px 0;
  }

  span {
    display: block;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
  }
`;
const Expect = styled.div`
  h3 {
    font-size: 20px;
    line-height: 23px;
    margin: 28px 0 15px;
  }
`;

const Icons = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 6px 6px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: ${Colors.boxShadow};
  width: 33.3%;
  width: calc(33.3% - 12px);
  height: 87px;
  position: relative;
  cursor: pointer;

  ${props =>
    props.active &&
    `
    background: #B6DDE0;
    transform: scale(1.05);
    svg path{
      stroke: #fff;
    } 
    span{
      color: #fff;
    }

    img{
      filter: invert(1);
    }
  `}

  @media ${Devices.laptop} {
    width: calc(25% - 12px);
  }

  span {
    font-size: 13px;
    line-height: 15px;
    display: block;
    text-align: center;
    font-family: baskerville-display-pt, serif;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
  }

  img {
    width: 50px;
    max-height: 45px;
    display: block;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 10px));
  }
`;

const AddPhoto = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  text-align: center;
  border: 1px dashed #a7a7a7;
  min-height: 72px;
  cursor: pointer;
  span {
    font-size: 8px;
  }
  span,
  h4 {
    color: rgba(105, 105, 105, 1);
  }

  display: none;
  @media ${Devices.laptop} {
    display: flex;
  }
`;

const Uploaded = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
  flex-wrap: wrap;

  > div {
    margin: 1.5%;
    height: 72px;
  }
`;

const Image = styled.div`
  width: 30%;
  box-shadow: ${Colors.boxShadow};
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
`;

const UploadTitle = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 0 15px;

  svg {
    position: relative;
    top: -2px;
  }
  h3 {
    text-align: center;
    margin-right: 18px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;
const ImageUpload = styled.div`
  background: #f8f8f8;
  margin-top: 17px;
  padding: 15px 5px 12px;
  position: relative;

  img {
    margin: 0 auto;
    display: block;

    @media ${Devices.laptop} {
      display: none;
    }
  }
`;

const AddWrapper = styled.div`
  /* margin: 19px 0; */
  max-width: 100%;

  position: relative;
  /* background: #fcfcfc; */

  @media ${Devices.laptop} {
    padding: 90px 0px 0px;
    max-width: 565px;
    /* margin: 0 auto; */
  }
`;

const Inputs = styled.div`
  padding: 0 31px;
  max-width: 100%;
  overflow-x: hidden;
  > div {
    margin: 18px 0;
  }
`;

const Title = styled.h2`
  ${Fonts.title}
  padding: 0 31px;
  padding-top: 19px;
  margin-bottom: 7px;
`;

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    setApp: e => {
      dispatch({
        type: 'SET_APP',
        payload: e
      });
    }
  };
};

const Index = connect(
  mapStateToProps,
  mapDispatchToProps
)(Add);

export default withRouter(Index);
