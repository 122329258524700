import React, { useState } from "react";
import styled from "styled-components/macro";

import Chev from "../../assets/chev.svg";

const Agegate = ({ updateCheckAge, updateAge }) => {
  let [value, setValue] = useState(null);
  return (
    <Wrapper>
      <Label>Year of birth</Label>
      <SelectWrapper>
        <img src={Chev} alt="" />
        <select
          onChange={e => {
            updateAge(e.target.value);
          }}
        >
          <option value="2019">2019</option>
          <option value="2018">2018</option>
          <option value="2017">2017</option>
          <option value="2016">2016</option>
          <option value="2015">2015</option>
          <option value="2014">2014</option>
          <option value="2013">2013</option>
          <option value="2012">2012</option>
          <option value="2011">2011</option>
          <option value="2010">2010</option>
          <option value="2009">2009</option>
          <option value="2008">2008</option>
          <option value="2007">2007</option>
          <option value="2006">2006</option>
          <option value="2005">2005</option>
          <option value="2004">2004</option>
          <option value="2003">2003</option>
          <option value="2002">2002</option>
          <option value="2001">2001</option>
          <option value="2000">2000</option>
          <option value="1999">1999</option>
          <option value="1998">1998</option>
          <option value="1997">1997</option>
          <option value="1996">1996</option>
          <option value="1995">1995</option>
          <option value="1994">1994</option>
          <option value="1993">1993</option>
          <option value="1992">1992</option>
          <option value="1991">1991</option>
          <option value="1990">1990</option>
          <option value="1989">1989</option>
          <option value="1988">1988</option>
          <option value="1987">1987</option>
          <option value="1986">1986</option>
          <option value="1985">1985</option>
          <option value="1984">1984</option>
          <option value="1983">1983</option>
          <option value="1982">1982</option>
          <option value="1981">1981</option>
          <option value="1980">1980</option>
          <option value="1979">1979</option>
          <option value="1978">1978</option>
          <option value="1977">1977</option>
          <option value="1976">1976</option>
          <option value="1975">1975</option>
          <option value="1974">1974</option>
          <option value="1973">1973</option>
          <option value="1972">1972</option>
          <option value="1971">1971</option>
          <option value="1970">1970</option>
          <option selected value="1969">
            1969
          </option>
          <option value="1968">1968</option>
          <option value="1967">1967</option>
          <option value="1966">1966</option>
          <option value="1965">1965</option>
          <option value="1964">1964</option>
          <option value="1963">1963</option>
          <option value="1962">1962</option>
          <option value="1961">1961</option>
          <option value="1960">1960</option>
          <option value="1959">1959</option>
          <option value="1958">1958</option>
          <option value="1957">1957</option>
          <option value="1956">1956</option>
          <option value="1955">1955</option>
          <option value="1954">1954</option>
          <option value="1953">1953</option>
          <option value="1952">1952</option>
          <option value="1951">1951</option>
          <option value="1950">1950</option>
          <option value="1949">1949</option>
          <option value="1948">1948</option>
          <option value="1947">1947</option>
          <option value="1946">1946</option>
          <option value="1945">1945</option>
          <option value="1944">1944</option>
          <option value="1943">1943</option>
          <option value="1942">1942</option>
          <option value="1941">1941</option>
          <option value="1940">1940</option>
          <option value="1939">1939</option>
          <option value="1938">1938</option>
          <option value="1937">1937</option>
          <option value="1936">1936</option>
          <option value="1935">1935</option>
          <option value="1934">1934</option>
          <option value="1933">1933</option>
          <option value="1932">1932</option>
          <option value="1931">1931</option>
          <option value="1930">1930</option>
          <option value="1929">1929</option>
          <option value="1928">1928</option>
          <option value="1927">1927</option>
          <option value="1926">1926</option>
          <option value="1925">1925</option>
          <option value="1924">1924</option>
          <option value="1923">1923</option>
          <option value="1922">1922</option>
          <option value="1921">1921</option>
          <option value="1920">1920</option>
          <option value="1919">1919</option>
          <option value="1918">1918</option>
          <option value="1917">1917</option>
          <option value="1916">1916</option>
          <option value="1915">1915</option>
          <option value="1914">1914</option>
          <option value="1913">1913</option>
          <option value="1912">1912</option>
          <option value="1911">1911</option>
          <option value="1910">1910</option>
          <option value="1909">1909</option>
          <option value="1908">1908</option>
          <option value="1907">1907</option>
          <option value="1906">1906</option>
          <option value="1905">1905</option>
          <option value="1904">1904</option>
          <option value="1903">1903</option>
          <option value="1902">1902</option>
          <option value="1901">1901</option>
        </select>
      </SelectWrapper>

      <Label>Location</Label>
      <SelectWrapper>
        <img src={Chev} alt="" />
        <select
          onChange={e => {
            updateCheckAge(e.target.value);
          }}
        >
          <option value="18" data-code="GB" data-id="826">
            United Kingdom
          </option>
          <option value="21" data-code="US" data-id="840">
            United States
          </option>
          <option value="18" data-code="AF" data-id="4">
            Afghanistan
          </option>
          <option value="18" data-code="AL" data-id="8">
            Albania
          </option>
          <option value="18" data-code="DZ" data-id="12">
            Algeria
          </option>
          <option value="21" data-code="AS" data-id="">
            American Samoa
          </option>
          <option value="18" data-code="AO" data-id="24">
            Angola
          </option>
          <option value="16" data-code="AG" data-id="28">
            Antigua and Barbuda
          </option>
          <option value="18" data-code="AR" data-id="32">
            Argentina
          </option>
          <option value="18" data-code="AM" data-id="51">
            Armenia
          </option>
          <option value="18" data-code="AU" data-id="36">
            Australia
          </option>
          <option value="18" data-code="AT" data-id="40">
            Austria
          </option>
          <option value="18" data-code="AZ" data-id="31">
            Azerbaijan
          </option>
          <option value="18" data-code="BS" data-id="44">
            Bahamas
          </option>
          <option value="18" data-code="BD" data-id="50">
            Bangladesh
          </option>
          <option value="18" data-code="BY" data-id="112">
            Belarus
          </option>
          <option value="18" data-code="BE" data-id="56">
            Belgium
          </option>
          <option value="18" data-code="BZ" data-id="84">
            Belize
          </option>
          <option value="18" data-code="BM" data-id="">
            Bermuda
          </option>
          <option value="18" data-code="BO" data-id="68">
            Bolivia
          </option>
          <option value="18" data-code="BA" data-id="70">
            Bosnia and Herzegovina
          </option>
          <option value="18" data-code="DZ" data-id="12">
            Botswana
          </option>
          <option value="18" data-code="BR" data-id="76">
            Brazil
          </option>
          <option value="16" data-code="VG" data-id="">
            British Virgin Islands
          </option>
          <option value="18" data-code="BN" data-id="96">
            Brunei
          </option>
          <option value="18" data-code="BG" data-id="100">
            Bulgaria
          </option>
          <option value="18" data-code="BI" data-id="108">
            Burundi
          </option>
          <option value="21" data-code="KH" data-id="116">
            Cambodia
          </option>
          <option value="21" data-code="CM" data-id="120">
            Cameroon
          </option>
          <option value="19" data-code="CA" data-id="124">
            Canada
          </option>
          <option value="18" data-code="CV" data-id="132">
            Cape Verde
          </option>
          <option value="18" data-code="KY" data-id="">
            Cayman Islands
          </option>
          <option value="18" data-code="CF" data-id="140">
            Central African Republic
          </option>
          <option value="18" data-code="CL" data-id="152">
            Chile
          </option>
          <option value="18" data-code="CN" data-id="156">
            China
          </option>
          <option value="18" data-code="CO" data-id="170">
            Colombia
          </option>
          <option value="18" data-code="KM" data-id="174">
            Comoros
          </option>
          <option value="18" data-code="CR" data-id="188">
            Costa Rica
          </option>
          <option value="18" data-code="HR" data-id="191">
            Croatia
          </option>
          <option value="18" data-code="CU" data-id="192">
            Cuba
          </option>
          <option value="17" data-code="CY" data-id="196">
            Cyprus
          </option>
          <option value="18" data-code="CZ" data-id="203">
            Czech Republic
          </option>
          <option value="18" data-code="CD" data-id="180">
            Democratic Republic of the Congo
          </option>
          <option value="18" data-code="DK" data-id="208">
            Denmark
          </option>
          <option value="18" data-code="DO" data-id="214">
            Dominican Republic
          </option>
          <option value="18" data-code="EC" data-id="218">
            Ecuador
          </option>
          <option value="21" data-code="EG" data-id="818">
            Egypt
          </option>
          <option value="18" data-code="SV" data-id="222">
            El Salvador
          </option>
          <option value="18" data-code="GQ" data-id="226">
            Equatorial Guinea
          </option>
          <option value="18" data-code="ER" data-id="232">
            Eritrea
          </option>
          <option value="18" data-code="EE" data-id="233">
            Estonia
          </option>
          <option value="18" data-code="ET" data-id="231">
            Ethiopia
          </option>
          <option value="18" data-code="FK" data-id="">
            Falkland Islands
          </option>
          <option value="18" data-code="FJ" data-id="242">
            Fiji
          </option>
          <option value="18" data-code="FI" data-id="246">
            Finland
          </option>
          <option value="18" data-code="FR" data-id="250">
            France
          </option>
          <option value="18" data-code="GA" data-id="266">
            Gabon
          </option>
          <option value="18" data-code="GM" data-id="270">
            Gambia
          </option>
          <option value="16" data-code="GE" data-id="268">
            Georgia
          </option>
          <option value="18" data-code="DE" data-id="276">
            Germany
          </option>
          <option value="18" data-code="GH" data-id="288">
            Ghana
          </option>
          <option value="18" data-code="GI" data-id="">
            Gibraltar
          </option>
          <option value="18" data-code="GR" data-id="300">
            Greece
          </option>
          <option value="21" data-code="GU" data-id="">
            Guam
          </option>
          <option value="18" data-code="GT" data-id="320">
            Guatemala
          </option>
          <option value="18" data-code="GW" data-id="624">
            Guinea-Bissau
          </option>
          <option value="18" data-code="GY" data-id="328">
            Guyana
          </option>
          <option value="16" data-code="HT" data-id="332">
            Haiti
          </option>
          <option value="18" data-code="HN" data-id="340">
            Honduras
          </option>
          <option value="18" data-code="HK" data-id="">
            Hong Kong
          </option>
          <option value="18" data-code="HU" data-id="348">
            Hungary
          </option>
          <option value="20" data-code="IS" data-id="352">
            Iceland
          </option>
          <option value="18" data-code="IN" data-id="356">
            India
          </option>
          <option value="21" data-code="ID" data-id="360">
            Indonesia
          </option>
          <option value="18" data-code="IR" data-id="364">
            Iran
          </option>
          <option value="18" data-code="JQ" data-id="368">
            Iraq
          </option>
          <option value="18" data-code="IE" data-id="372">
            Ireland
          </option>
          <option value="18" data-code="IL" data-id="376">
            Israel
          </option>
          <option value="18" data-code="IT" data-id="380">
            Italy
          </option>
          <option value="16" data-code="JM" data-id="388">
            Jamaica
          </option>
          <option value="20" data-code="JP" data-id="392">
            Japan
          </option>
          <option value="18" data-code="JO" data-id="400">
            Jordan
          </option>
          <option value="21" data-code="KZ" data-id="398">
            Kazakhstan
          </option>
          <option value="18" data-code="KE" data-id="404">
            Kenya
          </option>
          <option value="18" data-code="XK" data-id="">
            Kosovo
          </option>
          <option value="18" data-code="KW" data-id="414">
            Kuwait
          </option>
          <option value="18" data-code="KG" data-id="417">
            Kyrgyzstan
          </option>
          <option value="18" data-code="LV" data-id="428">
            Latvia
          </option>
          <option value="18" data-code="LB" data-id="422">
            Lebanon
          </option>
          <option value="18" data-code="LS" data-id="426">
            Lesotho
          </option>
          <option value="18" data-code="LY" data-id="434">
            Libya
          </option>
          <option value="18" data-code="LI" data-id="438">
            Liechtenstein
          </option>
          <option value="18" data-code="LT" data-id="440">
            Lithuania
          </option>
          <option value="16" data-code="LU" data-id="442">
            Luxembourg
          </option>
          <option value="18" data-code="MO" data-id="">
            Macau
          </option>
          <option value="18" data-code="MK" data-id="807">
            Macedonia
          </option>
          <option value="18" data-code="MW" data-id="454">
            Malawi
          </option>
          <option value="18" data-code="MY" data-id="458">
            Malaysia
          </option>
          <option value="18" data-code="MV" data-id="462">
            Maldives
          </option>
          <option value="17" data-code="MT" data-id="470">
            Malta
          </option>
          <option value="18" data-code="MU" data-id="480">
            Mauritius
          </option>
          <option value="21" data-code="FM" data-id="583">
            Federated States of Micronesia
          </option>
          <option value="18" data-code="MX" data-id="484">
            Mexico
          </option>
          <option value="16" data-code="MD" data-id="498">
            Moldova
          </option>
          <option value="18" data-code="MN" data-id="496">
            Mongolia
          </option>
          <option value="18" data-code="ME" data-id="499">
            Montenegro
          </option>
          <option value="16" data-code="MA" data-id="504">
            Morocco
          </option>
          <option value="18" data-code="MZ" data-id="508">
            Mozambique
          </option>
          <option value="18" data-code="NA" data-id="516">
            Namibia
          </option>
          <option value="18" data-code="NP" data-id="524">
            Nepal
          </option>
          <option value="18" data-code="NL" data-id="528">
            Netherlands
          </option>
          <option value="18" data-code="NZ" data-id="554">
            New Zealand
          </option>
          <option value="18" data-code="NI" data-id="558">
            Nicaragua
          </option>
          <option value="18" data-code="NE" data-id="562">
            Niger
          </option>
          <option value="18" data-code="NG" data-id="566">
            Nigeria
          </option>
          <option value="21" data-code="MP" data-id="">
            Northern Mariana Islands
          </option>
          <option value="18" data-code="KP" data-id="408">
            North Korea
          </option>
          <option value="20" data-code="NO" data-id="578">
            Norway
          </option>
          <option value="21" data-code="OM" data-id="512">
            Oman
          </option>
          <option value="21" data-code="PK" data-id="586">
            Pakistan
          </option>
          <option value="21" data-code="PW" data-id="585">
            Palau
          </option>
          <option value="16" data-code="PS" data-id="">
            Palestine
          </option>
          <option value="18" data-code="PA" data-id="591">
            Panama
          </option>
          <option value="18" data-code="PG" data-id="598">
            Papua New Guinea
          </option>
          <option value="20" data-code="PY" data-id="600">
            Paraguay
          </option>
          <option value="18" data-code="PE" data-id="604">
            Peru
          </option>
          <option value="18" data-code="PH" data-id="608">
            Philippines
          </option>
          <option value="18" data-code="PL" data-id="616">
            Poland
          </option>
          <option value="18" data-code="PT" data-id="620">
            Portugal
          </option>
          <option value="18" data-code="PR" data-id="">
            Puerto Rico
          </option>
          <option value="21" data-code="QA" data-id="634">
            Qatar
          </option>
          <option value="18" data-code="CG" data-id="178">
            Republic of the Congo
          </option>
          <option value="18" data-code="RO" data-id="642">
            Romania
          </option>
          <option value="18" data-code="RU" data-id="643">
            Russia
          </option>
          <option value="18" data-code="RW" data-id="646">
            Rwanda
          </option>
          <option value="18" data-code="WS" data-id="882">
            Samoa
          </option>
          <option value="18" data-code="SA" data-id="682">
            Saudi Arabia
          </option>
          <option value="18" data-code="SN" data-id="686">
            Senegal
          </option>
          <option value="18" data-code="RS" data-id="688">
            Serbia
          </option>
          <option value="18" data-code="SC" data-id="690">
            Seychelles
          </option>
          <option value="18" data-code="SG" data-id="702">
            Singapore
          </option>
          <option value="18" data-code="SO" data-id="706">
            Somalia
          </option>
          <option value="21" data-code="SB" data-id="90">
            Solomon Islands
          </option>
          <option value="18" data-code="ZA" data-id="710">
            South Africa
          </option>
          <option value="19" data-code="KR" data-id="410">
            South Korea
          </option>
          <option value="18" data-code="SS" data-id="728">
            South Sudan
          </option>
          <option value="18" data-code="SK" data-id="703">
            Slovakia
          </option>
          <option value="18" data-code="SI" data-id="705">
            Slovenia
          </option>
          <option value="18" data-code="ES" data-id="724">
            Spain
          </option>
          <option value="21" data-code="LK" data-id="144">
            Sri Lanka
          </option>
          <option value="18" data-code="SD" data-id="729">
            Sudan
          </option>
          <option value="18" data-code="SZ" data-id="748">
            Swaziland
          </option>
          <option value="18" data-code="SE" data-id="752">
            Sweden
          </option>
          <option value="18" data-code="CH" data-id="756">
            Switzerland
          </option>
          <option value="18" data-code="SY" data-id="760">
            Syria
          </option>
          <option value="18" data-code="TW" data-id="">
            Taiwan
          </option>
          <option value="21" data-code="TJ" data-id="762">
            Tajikistan
          </option>
          <option value="18" data-code="TZ" data-id="834">
            Tanzania
          </option>
          <option value="21" data-code="TH" data-id="764">
            Thailand
          </option>
          <option value="18" data-code="TG" data-id="768">
            Togo
          </option>
          <option value="18" data-code="TK" data-id="">
            Tokelau
          </option>
          <option value="21" data-code="TO" data-id="776">
            Tonga
          </option>
          <option value="18" data-code="TT" data-id="780">
            Trinidad and Tobago
          </option>
          <option value="18" data-code="TN" data-id="788">
            Tunisia
          </option>
          <option value="18" data-code="TR" data-id="792">
            Turkey
          </option>
          <option value="18" data-code="TM" data-id="795">
            Turkmenistan
          </option>
          <option value="18" data-code="UG" data-id="800">
            Uganda
          </option>
          <option value="18" data-code="UA" data-id="804">
            Ukraine
          </option>
          <option value="21" data-code="AE" data-id="784">
            United Arab Emirates
          </option>
          <option value="18" data-code="GB" data-id="826">
            United Kingdom
          </option>
          <option value="21" data-code="US" data-id="840">
            United States
          </option>
          <option value="18" data-code="VI" data-id="">
            United States Virgin Islands
          </option>
          <option value="18" data-code="UY" data-id="858">
            Uruguay
          </option>
          <option value="18" data-code="VU" data-id="548">
            Vanuatu
          </option>
          <option value="18" data-code="VE" data-id="862">
            Venezuela
          </option>
          <option value="18" data-code="VN" data-id="704">
            Vietnam
          </option>
          <option value="18" data-code="YE" data-id="887">
            Yemen
          </option>
          <option value="18" data-code="ZM" data-id="894">
            Zambia
          </option>
          <option value="18" data-code="ZW" data-id="716">
            Zimbabwe
          </option>
        </select>
      </SelectWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 15px 0;
  position: relative;
  z-index: 2;
  select {
    color: #fff;
    padding: 8px 5px;
    font-size: 16px;
    outline: none;
    max-width: 100%;
    background: transparent;
    border-color: transparent;
    font-family: baskerville-display-pt, serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    cursor: pointer;

    &::-ms-expand {
      display: none;
    }
  }
`;

const Label = styled.label`
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  display: block;
  margin-top: 15px;
`;

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  padding-right: 22px;
  /* background: rgba(0, 0, 0, 0.5); */
  background: #fff;
  border-radius: 0px;
  border: 1px solid #cbcbcb;
  margin-top: 15px;
  max-width: 200px;

  option {
    background: #fff !important;
  }

  select {
    color: #000;
  }

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
`;

export default Agegate;
