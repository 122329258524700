import {
  LOAD_LOCATIONS_PENDING,
  LOAD_LOCATIONS_SUCCESS,
  LOAD_LISTS_PENDING,
  LOAD_LISTS_SUCCESS,
  LOAD_LOCATION_PENDING,
  LOAD_LOCATION_SUCCESS,
  LOAD_RELATED_LOCATIONS_SUCCESS,
  LOAD_LIST_PENDING,
  LOAD_LIST_SUCCESS,
  SET_APP,
  LOAD_STORY_LOCATION_SUCCESS,
} from "../actions/action-types.js"
import axios from "axios"
import { createClient } from "contentful"

let client = createClient({
  space: "7s9yafw5qome",
  accessToken: "IqvOWMwT-gjrsJXgUbWqgGYL9r5X_CQHYxluZikVWh4",
  host: "cdn.contentful.com",
})

const Actions = {
  loadLocations: (filter) => async (dispatch) => {
    const getShuffledArr = (arr) => {
      const newArr = arr.slice()
      for (let i = newArr.length - 1; i > 0; i--) {
        const rand = Math.floor(Math.random() * (i + 1))
        ;[newArr[i], newArr[rand]] = [newArr[rand], newArr[i]]
      }
      return newArr
    }

    dispatch({ type: LOAD_LOCATIONS_PENDING })

    // if (filter == undefined) {
    //   filter = { limit: 20 }
    // }

    // filter.limit = 20
    let request = await client.getEntries({
      content_type: "location",
      order: "-sys.createdAt",
      ...filter,
    })

    let data = request.items.map((item) => {
      return {
        ...item.fields,
        images: item.fields.images
          ? item.fields.images.map((image) => {
              return image.fields.file.url
            })
          : [],
      }
    })

    // data = getShuffledArr(data)

    dispatch({
      type: LOAD_LOCATIONS_SUCCESS,
      payload: data,
    })

    dispatch({
      type: SET_APP,
      payload: {
        loaded: true,
      },
    })
  },

  loadRelatedLocations: (location) => async (dispatch) => {
    if (!location) {
      return
    }

    const getShuffledArr = (arr) => {
      const newArr = arr.slice()
      for (let i = newArr.length - 1; i > 0; i--) {
        const rand = Math.floor(Math.random() * (i + 1))
        ;[newArr[i], newArr[rand]] = [newArr[rand], newArr[i]]
      }
      return newArr
    }

    let request = await client.getEntries({
      content_type: "location",
      "fields.location[near]": `${location.location.lat},${location.location.lon}`,
      "fields.isAccommodation": location.isAccommodation,
      limit: 4,
    })

    request.items = request.items.filter(function (obj) {
      return obj.fields.slug !== location.slug
    })

    let data = request.items.map((item) => {
      return {
        ...item.fields,
        images: item.fields.images
          ? item.fields.images.map((image) => {
              return image.fields.file.url
            })
          : [],
      }
    })

    dispatch({
      type: LOAD_RELATED_LOCATIONS_SUCCESS,
      payload: data,
    })
  },
  loadLists: (filter) => async (dispatch) => {
    dispatch({ type: LOAD_LISTS_PENDING })

    let request = await client.getEntries({
      content_type: "list",
      ...filter,
    })

    let data = request.items.map((item) => {
      return {
        ...item.fields,
        image: item.fields.image.fields.file.url,
      }
    })

    dispatch({
      type: LOAD_LISTS_SUCCESS,
      payload: data,
    })

    dispatch({
      type: SET_APP,
      payload: {
        loaded: true,
      },
    })
  },
  loadLocation: (slug) => async (dispatch) => {
    dispatch({ type: LOAD_LOCATION_PENDING })

    if (slug == null) {
      dispatch({
        type: LOAD_LOCATION_SUCCESS,
        payload: null,
      })
      return
    }

    let request = await client.getEntries({
      content_type: "location",
      "fields.slug": slug,
    })

    let data = request.items.map((item) => {
      return {
        ...item.fields,
        images: item.fields.images.map((image) => {
          return image.fields.file.url
        }),
      }
    })

    dispatch({
      type: LOAD_LOCATION_SUCCESS,
      payload: data[0],
    })
  },

  loadStoryLocation: (slug) => async (dispatch) => {
    let request = await client.getEntries({
      content_type: "location",
      "fields.slug": slug,
    })

    let data = request.items.map((item) => {
      return {
        ...item.fields,
        images: item.fields.images.map((image) => {
          return image.fields.file.url
        }),
      }
    })

    dispatch({
      type: LOAD_STORY_LOCATION_SUCCESS,
      payload: data[0],
    })
  },

  loadList: (slug) => async (dispatch) => {
    dispatch({ type: LOAD_LIST_PENDING })
    let request = await client.getEntries({
      content_type: "list",
      "fields.slug": slug,
    })

    let data = request.items.map((item) => {
      return {
        ...item.fields,
        image: item.fields.image.fields.file.url,
        locations: item.fields.locations.map((location) => {
          return { ...location.fields }
        }),
      }
    })

    dispatch({
      type: LOAD_LIST_SUCCESS,
      payload: data[0],
    })
  },
}

export default Actions
