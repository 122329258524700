import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Devices from '../../consts/Devices';

const Cookies = ({}) => {
  const [accepted, setAccpeted] = useState(window.localStorage.getItem('cookie') ? true : false);
  return (
    <Wrapper accepted={accepted}>
      <span>
        We use cookies to make your experience better. By using this site you consent to our{' '}
        <a href="https://offgrid.theshackletonwhisky.com/privacy-policy" target="_blank">
          privacy policy
        </a>
        .
      </span>
      <AcceptBtn
        onClick={() => {
          setAccpeted(true);
          window.localStorage.setItem('cookie', true);
        }}
      >
        <h3>I Accept</h3>
      </AcceptBtn>
    </Wrapper>
  );
};

const AcceptBtn = styled.div`
  @media (min-width: 1375px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 65px;
    h3 {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  background: rgba(26, 25, 25, 0.9);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  text-align: center;
  transition: bottom 0.3s ease-in;

  ${props =>
    props.accepted &&
    `
    bottom: -200px!important;
  `}

  @media (min-width: 1375px) {
    display: flex;
    justify-content: space-between;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    padding: 0 25px;
    max-width: 750px;
  }

  span {
    color: #fff;
    font-family: baskerville-display-pt, serif;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    display: block;
    max-width: 222px;
    margin: 0 auto;
    padding: 22px 0;
    @media (min-width: 1375px) {
      max-width: none;

      margin: 0;
    }
    a {
      border-bottom: 1px solid #fff;
    }
  }

  h3 {
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    border-bottom: 1px solid #fff;
    margin: 0 auto 23px;
    display: inline-block;
    padding-bottom: 1px;
    cursor: pointer;
    @media (min-width: 1375px) {
      margin-bottom: 0;
      height: 25px;
    }
  }
`;

export default Cookies;
