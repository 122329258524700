import React, { useState, useEffect, useRef } from "react"
import { withRouter } from "react-router-dom"

const DefaultLoader = (props) => {
  useEffect(() => {
    // props.loadLocations()
  }, [])
  return <span />
}

export default withRouter(DefaultLoader)
