import React, { Component } from "react"
import "./App.css"
import "@csstools/normalize.css"
import { connect } from "react-redux"
import styled, { css } from "styled-components/macro"
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  withRouter,
} from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import initReactFastclick from "react-fastclick"
import smoothscroll from "smoothscroll-polyfill"
import { Helmet } from "react-helmet"
import Splash from "../../components/Splash"

import DataActions from "../../actions/dataActions"
import LocationActions from "../../actions/locationActions"
import Devices from "../../consts/Devices"

import Discover from "../../containers/Discover"
import Add from "../../containers/Add"
import Story from "../../containers/Story"
import Lists from "../../containers/Lists"
import Location from "../../containers/Location"
import List from "../../containers/List"
import Map from "../../components/Map"
import Layout from "../../components/Layout"
import Hero from "../../components/Hero"
import Actions from "../../components/Actions"
import Cookies from "../../components/cookies"
import Privacy from "../privacy/Privacy"

import LocationLoader from "../../components/Loaders/locations"
import ListsLoader from "../../components/Loaders/lists"
import ListLoader from "../../components/Loaders/list"
import AddLoader from "../../components/Loaders/add"
import PrivacyLoader from "../../components/Loaders/privacy"
import StayLoader from "../../components/Loaders/stay"
import DefaultLoader from "../../components/Loaders/default"

import Menu from "../../components/Menu"
import AddButton from "../../components/Add"
import Logo from "../../components/Logo"

import ListsHead from "../../assets/lists-head.jpg"
import AddHead from "../../assets/add-head.jpg"

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clearingLocation: false,
      viewLoaded: false,
    }
  }

  componentDidMount() {
    initReactFastclick()
    if (window.location.href.indexOf("stay") > -1) {
      this.props.loadLocations({ "fields.isAccommodation": true })
    } else {
      this.props.loadLocations()
    }

    smoothscroll.polyfill()
    window.onpopstate = (e) => {
      this.props.clearLocation()

      this.props.setApp({
        canGoBack: false,
      })
    }
  }

  getFirst() {
    switch (this.props.view) {
      case "DISCOVER":
        return <Discover />
        break
      case "LISTS":
        return <Lists />
        break
      case "LIST":
        return <List />
        break
      case "ADD":
        return <Add />
        break
      case "PRIVACY":
        return <Privacy />
        break
      default:
        return <Discover />
    }
  }

  getSecond() {
    switch (this.props.view) {
      case "DISCOVER":
        return (
          <Map
            locations={this.props.locations.locations}
            currentLocationIndex={this.props.curentLocationIndex}
            geoLocation={
              this.props.locations.locations[this.props.curentLocationIndex]
            }
            loadLocations={this.props.loadLocations}
            updateFilterTag={this.props.updateFilterTag}
            updateCurrentLocation={this.props.updateCurrentLocation}
            filterTag={this.props.filterTag}
            loadLocation={this.props.loadLocation}
            userLocation={this.props.userLocation}
          />
        )
        break
      case "LISTS":
        return <Hero images={[ListsHead]} onLeft={true} intro={true} />
        break
      case "LIST":
        return (
          <Hero
            images={this.props.list ? [this.props.list.image] : []}
            onLeft={true}
            intro={true}
          />
        )

        break
      case "ADD":
        return <Hero images={[AddHead]} onLeft={true} intro={true} />

        break
      case "PRIVACY":
        return <Hero images={[AddHead]} onLeft={true} intro={true} />

        break
      default:
        return (
          <Map
            locations={this.props.locations.locations}
            currentLocationIndex={this.props.curentLocationIndex}
            geoLocation={
              this.props.locations.locations[this.props.curentLocationIndex]
            }
            updateFilterTag={this.props.updateFilterTag}
            loadLocations={this.props.loadLocations}
            userLocation={this.props.userLocation}
          />
        )
    }
  }

  render() {
    return (
      <Wrapper layered={this.props.layered}>
        <Helmet>
          <title>Shackleton X Off Grid</title>
          <meta property="og:title" content="Shackleton X Off Grid" />
          <meta
            property="og:description"
            content="Embody the spirit of the 1907 British Antarctic exploration and go off grid."
          />
          {/* <meta property="og:image" content={`https:${location.images[0]}`} /> */}
          <meta
            property="og:url"
            content={`https://offgrid.theshackletonwhisky.com`}
          />
          <meta property="og:type" content="website" />
        </Helmet>

        {!this.props.seenSplash &&
        window.location.href.indexOf("privacy") < 0 ? (
          <Splash
            hide={() => {
              this.setState({ showSplash: false })
              this.props.setApp({
                seenSplash: true,
              })
            }}
            visible={this.state.showSplash}
            // onLocation={location.data || null}
          />
        ) : (
          <Router>
            <Menu
              loaded={this.props.loaded}
              canGoBack={this.props.canGoBack}
              setApp={this.props.setApp}
              seenSplash={this.props.seenSplash}
              clearLocation={() => {
                this.setState({
                  clearingLocation: true,
                })
                setTimeout(() => {
                  this.props.clearLocation()
                  this.setState({ clearingLocation: false })
                }, 500)
              }}
            />

            <AddButton
              setApp={this.props.setApp}
              geoLocation={this.props.location.data}
              loaded={this.props.loaded}
              loadLocations={this.props.loadLocations}
              updateFilterTag={this.props.updateFilterTag}
              loadGeo={this.props.loadGeo}
              seenSplash={this.props.seenSplash}
            />

            <Logo loaded={this.props.loaded} />

            <Location
              clearingLocation={this.state.clearingLocation}
              clearLocation={() => {
                this.setState({
                  clearingLocation: true,
                })
                setTimeout(() => {
                  this.props.clearLocation()
                  this.setState({ clearingLocation: false })
                }, 500)
              }}
            />

            {this.props.location.data && (
              <Actions
                location={this.props.location.data}
                clearning={this.state.clearingLocation}
              />
            )}

            <Route
              exact
              path="/"
              render={() => {
                return (
                  <DefaultLoader loadLocations={this.props.loadLocations} />
                )
              }}
            />

            <Route
              exact
              path="/stay"
              render={() => {
                return (
                  <StayLoader
                    updateFilterTag={this.props.updateFilterTag}
                    loadLocations={this.props.loadLocations}
                  />
                )
              }}
            />

            <Route
              exact
              path="/privacy-policy"
              render={() => {
                return <PrivacyLoader setApp={this.props.setApp} />
              }}
            />

            <Route
              exact
              path="/location/:slug"
              render={() => {
                return (
                  <LocationLoader
                    setApp={this.props.setApp}
                    loadLocation={this.props.loadLocation}
                  />
                )
              }}
            />
            <Route
              exact
              path="/lists"
              render={() => {
                return (
                  <ListsLoader
                    setApp={this.props.setApp}
                    loadLists={this.props.loadLists}
                  />
                )
              }}
            />

            <Route
              exact
              path="/add"
              render={() => {
                return (
                  <AddLoader
                    setApp={this.props.setApp}
                    clearLocation={() => {
                      this.props.clearLocation()
                    }}
                  />
                )
              }}
            />
            <Route
              exact
              path="/list/:slug"
              render={() => {
                return (
                  <ListLoader
                    setApp={this.props.setApp}
                    loadList={this.props.loadList}
                  />
                )
              }}
            />

            <Switch>
              <Route exact path="/story/:slug" component={Story} />
              <Route
                path="/"
                render={() => {
                  return (
                    <Layout
                      first={this.getFirst()}
                      second={this.getSecond()}
                      loaded={this.state.viewLoaded}
                      locked={this.props.locked}
                    />
                  )
                }}
              />
            </Switch>
          </Router>
        )}
        <Cookies />

        {this.props.location.data && (
          <Background
            onClick={() => {
              this.props.setApp({
                canGoBack: false,
                layered: false,
              })
              this.props.clearLocation()
              this.props.history.push("/")
            }}
          />
        )}
      </Wrapper>
    )
  }
}

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: none;
  background: rgba(0, 0, 0, 0.4);
  @media ${Devices.laptop} {
    display: block;
  }
`

const Wrapper = styled.div`
  /* ${(props) =>
    props.layered
      ? `
  position: fixed;
`
      : ``} */
`

const mapStateToProps = (state) => {
  return {
    locations: state.locations,
    location: state.location,
    lists: state.lists.lists,
    list: state.list,
    canGoBack: state.app.canGoBack,
    curentLocationIndex: state.locations.currentIndex,
    view: state.app.view,
    loaded: state.app.loaded,
    layered: state.app.layered,
    locked: state.app.locked,
    filterTag: state.locations.filterTag,
    userLocation: state.app.userLocation,
    seenSplash: state.app.seenSplash,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadLocations: (filter) => {
      dispatch(DataActions.loadLocations(filter))
    },
    updateCurrentLocation: (i) => {
      dispatch(LocationActions.updateCurrentLocation(i))
    },
    updateFilterTag: (i) => {
      dispatch(LocationActions.updateFilterTag(i))
    },
    loadLists: () => {
      dispatch(DataActions.loadLists())
    },
    loadList: (slug) => {
      dispatch(DataActions.loadList(slug))
    },
    loadLocation: (slug) => {
      dispatch(DataActions.loadLocation(slug))
    },
    setApp: (e) => {
      dispatch({
        type: "SET_APP",
        payload: e,
      })
    },
    clearLocation: (e) => {
      dispatch({
        type: "LOAD_LOCATION_SUCCESS",
        payload: null,
      })
    },
    loadGeo: (e) => {
      dispatch({
        type: "LOAD_LOCATIONS_PENDING",
      })
    },
  }
}

const Index = connect(mapStateToProps, mapDispatchToProps)(App)

export default React.memo(withRouter(Index))
