import React, { Component } from "react"
import { connect } from "react-redux"
import styled, { css } from "styled-components/macro"
import { withRouter, Link } from "react-router-dom"
import Fonts from "../../consts/Fonts"
import Colors from "../../consts/Colors"
import Devices from "../../consts/Devices"
import Media from "react-media"
import { TweenLite } from "gsap"
import { Helmet } from "react-helmet"
import { trackPage } from "react-with-analytics"
import SVG from "react-inlinesvg"

import DataActions from "../../actions/dataActions"
import LocationActions from "../../actions/locationActions"

import Hero from "../../components/Hero"
import Carousel from "../../components/Carousel"
import CarouselDots from "../../components/CarouselDots"
import Weather from "../../components/Weather"
import Instagram from "./Instagram"
import Slide from "../../components/Slide"
import Host from "./Host"

import BullsEye from "../../assets/bulls-eye.svg"
import Torn from "../../assets/torn.svg"
import BedIcon from "../../assets/bedIcon.svg"
import WifiIcon from "../../assets/wifiIcon.svg"

const markerURL = "https://off-grid.netlify.com/map-marker.png"

class Location extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSlideIndex: 0,
      // shuffledLocations: []
    }
  }

  componentDidMount() {
    if (this.props.location) {
      this.props.setApp({
        layered: true,
      })
    } else {
      this.props.setApp({
        layered: false,
      })
    }
    trackPage(window.location.pathname + window.location.search)
    // var newObject = JSON.parse(JSON.stringify(this.props.locations));
    // const shuffledLocations = newObject.sort(() => 0.5 - Math.random()).slice(0, 5);
    // this.setState({shuffledLocations:shuffledLocations})
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location == null) {
      this.props.loadRelatedLocations(this.props.location)
    }
  }

  render() {
    let {
      location,
      layered,
      bound,
      locations,
      dataLoading,
      setLocation,
      clearingLocation,
      getWeather,
      clearLocation,
    } = this.props

    return (
      <LocationWrapperOuter
        layered={layered}
        ref={"wrapper"}
        loaded={location !== null}
        clearingLocation={clearingLocation}
      >
        {location && (
          <Helmet>
            <title>{location.title} x Shackleton Off Grid</title>
            <meta
              property="og:title"
              content={`${location.title} X Shackleton Off Grid`}
            />
            <meta property="og:description" content={location.description} />
            <meta property="og:image" content={`https:${location.images[0]}`} />
            <meta
              property="og:url"
              content={`https://offgrid.theshackletonwhisky.com/loaction/${location.slug}`}
            />
            <meta property="og:type" content="website" />
          </Helmet>
        )}

        <Media query={Devices.laptop}>
          <CloseBtn
            onClick={() => {
              this.props.setApp({
                canGoBack: false,
                layered: false,
              })
              this.props.clearLocation()
              this.props.history.push("/")
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="22.999" height="23">
              <g data-name="Group 421">
                <path
                  data-name="Path 127"
                  d="M1.25 1.25l20.5 20.5"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2.5"
                />
                <g data-name="Group 420">
                  <path
                    data-name="Path 128"
                    d="M21.749 1.25l-20.5 20.5"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-width="2.5"
                  />
                </g>
              </g>
            </svg>
          </CloseBtn>
        </Media>
        {location && (
          <Hero
            bound={bound}
            size={"large"}
            images={location.images}
            onLocation={true}
            clearingLocation={clearingLocation}
            portrait={this.props.location.isPortrait}
          />
        )}

        <LocationWrapperInner
          loaded={location !== null}
          clearingLocation={clearingLocation}
          portrait={location && location.isPortrait}
        >
          {location && (
            <LocationWrapper ref={this.wrapper}>
              <TornEdge />
              <Padded>
                <Title>{location.title}</Title>
                <Address>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23.363"
                    height="18.928"
                  >
                    <g data-name="Group 266">
                      <g transform="translate(6.663)" data-name="Group 91">
                        <path
                          data-name="Path 36"
                          d="M5.423.002A5.725 5.725 0 0 0 0 5.717a5.825 5.825 0 0 0 .468 1.847c.044.118.09.234.125.334a.408.408 0 0 0 .017.05l4.178 8.347a1.042 1.042 0 0 0 .919.593h.008a1.043 1.043 0 0 0 .919-.593l4.194-8.356a.409.409 0 0 0-.184-.543.4.4 0 0 0-.535.175l-4.193 8.337a.215.215 0 0 1-.2.125.225.225 0 0 1-.209-.125l-4.17-8.311c-.035-.1-.074-.2-.117-.318A5.308 5.308 0 0 1 .8 5.717a4.917 4.917 0 0 1 9.651-1.337.4.4 0 0 0 .493.284.412.412 0 0 0 .284-.5A5.742 5.742 0 0 0 5.715.002c-.098 0-.195-.005-.292 0z"
                          fill-rule="evenodd"
                        />
                        <circle
                          data-name="Ellipse 29"
                          cx="1.489"
                          cy="1.489"
                          r="1.489"
                          transform="translate(4.113 3.722)"
                        />
                      </g>
                      <path
                        data-name="Path 99"
                        d="M8.177 14.128H5.332L.907 18.554H22.99c.021 0-3.32-4.426-3.32-4.426h-2.955"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-width=".75"
                      />
                    </g>
                  </svg>
                  <span>{location.address}</span>
                </Address>
                <Description>
                  <p>{location.description}</p>
                </Description>

                {location.instagramHandle && location.instagramHandle && (
                  <Instagram location={location} />
                )}

                {location.isAccommodation && <Host location={location} />}

                {location.icons && !location.isAccommodation && (
                  <Expect>
                    <h3>What you can expect</h3>
                    <Icons>
                      {location.icons.map((icon, i) => {
                        if (!icon.fields) {
                          return <span />
                        }
                        return (
                          <Icon key={i}>
                            {/* <img src={icon.fields.icon.fields.file.url} /> */}
                            <SVG src={icon.fields.icon.fields.file.url} />
                            <span>{icon.fields.title}</span>
                          </Icon>
                        )
                      })}
                    </Icons>
                  </Expect>
                )}

                {location.isAccommodation && (
                  <Expect className="acom">
                    <h3>Accommodation details</h3>
                    <Icons>
                      <Icon>
                        <SVG src={BedIcon} />
                        <span>{location.hosts ? location.hosts : ""}</span>
                      </Icon>
                      <Icon>
                        <h4>{location.price ? location.price : ""}</h4>
                        <span>
                          {location.priceSub ? location.priceSub : ""}
                        </span>
                      </Icon>
                      <Icon>
                        <SVG src={WifiIcon} />
                        <span>No Wifi</span>
                      </Icon>
                    </Icons>
                  </Expect>
                )}
              </Padded>

              <Map>
                <img
                  src={
                    location.mapImage
                      ? location.mapImage.fields.file.url
                      : `https://api.mapbox.com/styles/v1/neustudio/cjxx89ndo7xac1cocc7u1djni/static/url-${encodeURIComponent(
                          markerURL
                        )}(${location.location.lon},${location.location.lat})/${
                          location.location.lon
                        },${
                          location.location.lat
                        },7,0,0/600x400@2x?access_token=pk.eyJ1IjoibmV1c3R1ZGlvIiwiYSI6ImNqcnRhdmRydDF5NngzeXBzdTFkZ2h2NHAifQ.SmuHlMGPDvDf_fXM9Oh31Q
            `
                  }
                  alt=""
                />
                <CoOrds>
                  <div>
                    <img src={BullsEye} />
                    <span>{location.location.lat.toFixed(4)}</span>
                  </div>
                  <div>
                    <img src={BullsEye} />
                    <span>{location.location.lon.toFixed(4)}</span>
                  </div>
                </CoOrds>
              </Map>

              {/* <WeatherWrapper>
                <h3>5 day weather forecast</h3>
                <Weather
                  geoLocation={location}
                  getWeather={location.forecast ? () => {} : getWeather}
                  days={5}
                  iconStyle={{ borderColor: 'rgba(0,0,0,0.05)' }}
                />
              </WeatherWrapper> */}

              <Related>
                <RelatedHead>
                  <h3>
                    Related{" "}
                    {location.isAccommodation ? "Accommodation" : "Off Grid"}
                  </h3>
                  <CarouselDots
                    items={this.props.relatedLocations}
                    currentIndex={this.state.currentSlideIndex}
                    color={"#1A1919"}
                  />
                </RelatedHead>

                <CarouselComp
                  locations={this.props.relatedLocations}
                  setApp={(e) => {
                    this.props.setApp(e)
                  }}
                  updateCurrentLocation={(i) => {
                    this.setState({ currentSlideIndex: i })
                  }}
                  loading={dataLoading}
                  onLocation={true}
                  onClick={() => {
                    //TO DO - smooth this
                    this.refs.wrapper.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    })
                  }}
                />

                <DesktopCards>
                  {this.props.relatedLocations
                    .slice(0, 2)
                    .map((location, i) => {
                      return (
                        <DesktopSlide
                          key={i}
                          index={i}
                          geoLocation={location}
                          filterTag={this.props.locations.filterTag}
                          active={false}
                          locationsLoading={this.props.locationsLoading}
                          onClick={() => {
                            this.refs.wrapper.scroll({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            })
                          }}
                        />
                      )
                    })}
                </DesktopCards>
              </Related>
            </LocationWrapper>
          )}
        </LocationWrapperInner>
      </LocationWrapperOuter>
    )
  }
}

const DesktopCards = styled.div`
  display: none;
  @media ${Devices.laptop} {
    display: flex;
    flex-wrap: wrap;
    /* width: 50%; */
    position: relative;
    z-index: 2;
    position: absolute;
    /* right: 20px; */
    padding-bottom: 100px;
  }

  @media (min-width: 1700px) {
    position: static;
    padding-bottom: 0px;
    justify-content: center;
    margin: 0 auto;
  }

  padding: 0 25px;
`

const DesktopSlide = styled(Slide)`
  height: 470px;
  width: 100%;
  max-width: none;
  &:nth-child(1) {
    display: none;
  }
  @media ${Devices.laptopL} {
    width: 50%;
    max-width: 640px;
    &:nth-child(1) {
      display: block;
    }
  }
  a {
    width: 100%;
  }
  a > div {
    margin: 0 !important;
    width: 100%;
  }
  cursor: pointer;
  padding: 10px 10px;

  span span {
    height: 100%;
    width: 258px;
    margin-right: 22px;
  }
`

const CarouselComp = styled(Carousel)`
  @media ${Devices.laptop} {
    display: none;
  }
`

const CoOrds = styled.div`
  display: flex;
  position: absolute;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);

  > div {
    border-radius: 3px;
    box-shadow: ${Colors.boxShadow};
    background: #fff;
    padding: 10px;
    margin: 0 2px;
    display: flex;
    img {
      width: 18px;
      margin-right: 5px;
    }

    span {
      color: #4a4a4a;
      font-size: 15px;
      line-height: 20px;
      font-family: baskerville-display-pt, serif;
    }
  }
`

const Related = styled.div`
  /* padding-bottom: 200px; */
  background: #f5f5f5;
  padding-top: 40px;
  padding-bottom: 220px;
  position: relative;
  @media ${Devices.laptop} {
    padding-bottom: 579px;
  }

  @media (min-width: 1700px) {
    padding-bottom: 130px;
  }

  h3 {
    color: ${Colors.nearBlack};
    font-size: 20px;
    line-height: 24px;
  }
`

const RelatedHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px 40px;
  @media ${Devices.laptop} {
    margin: 0 auto;
    padding: 0 30px 40px;
    max-width: 600px;
  }
`

const WeatherWrapper = styled.div`
  padding: 0 30px 25px 30px;
  background: #fff;
  min-height: 200px;

  @media ${Devices.laptop} {
    h3,
    > div {
      margin: 0 auto;
      padding: 0 30px;
      max-width: 600px;
    }
  }

  h3 {
    color: ${Colors.nearBlack};
    font-size: 20px;
    line-height: 24px;
    padding-top: 42px;
    margin-bottom: 25px;
  }
  > div {
    background: #fdfdfd;
    box-shadow: ${Colors.boxShadow};

    padding: 15px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    span {
      color: #707070 !important;
    }
  }
  svg path {
    fill: #707070 !important;
  }
`

const CloseBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: ${Colors.blue};
  z-index: 5;
  width: 65px;
  height: 65px;
  cursor: pointer;
  @media ${Devices.laptop} {
    &:hover {
      svg path {
        stroke: #9bbfc1;
      }
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media ${Devices.laptop} {
      path {
        transition: stroke 0.3s;
      }
    }
  }
`

const StyledHero = styled(Hero)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  ${(props) =>
    props.bound
      ? `
        top: ${props.bound.top}px;
        left: ${props.bound.left}px;
        width: ${props.bound.width}px;
        height: ${props.bound.height}px;
        `
      : ``}
`

const LocationWrapperOuter = styled.div`
  transform: translateY(200vh);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  ${(props) =>
    props.loaded
      ? `
        transform: translateY(0%);
      `
      : ``}

  ${(props) =>
    props.clearingLocation
      ? `
        transform: translateY(100%);
      `
      : ``}
  left: 0;
  right: 0;
  position: fixed;
  z-index: 9;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transition: all 0.5s;
  min-height: 450px;

  @media ${Devices.laptop} {
    right: -100%;
    opacity: 1;
    ${(props) =>
      props.loaded && !props.clearLocation
        ? `
      right: 0%;
      `
        : ``}

    ${(props) =>
      props.clearingLocation
        ? `
          
        `
        : ``}
    left: auto;
    min-height: 100vh;
    width: 40%;
    display: flex;
    justify-content: flex-end;
    /* background: rgba(0, 0, 0, 0.4); */
    z-index: 5;
    transition: right 0.5s;
    transition: transform 0.5s;
  }
`

const LocationWrapperInner = styled.div`
  position: relative;
  top: 450px;
  transition: all 1s;
  z-index: 5;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  @media ${Devices.laptop} {
    right: 0;
    left: auto;
    min-height: 100vh;
    width: 100%;
    box-shadow: ${Colors.boxShadow};
    ${(props) =>
      props.portrait &&
      `
      top: 740px;
    `}
  }
`

const Map = styled.div`
  position: relative;
  img {
    max-width: 100%;
    vertical-align: bottom;
    width: 100%;
  }
`

const Icons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* margin: 0 17px; */

  @media ${Devices.laptop} {
    margin: 0;
  }
`

const Expect = styled.div`
  padding: 20px 0 30px;
  background: #fff;

  &.acom {
    > div {
      justify-content: space-between;
    }
    svg {
      * {
        stroke: #000;
      }
    }

    h4 {
      font-family: baskerville-display-pt, serif;
      display: block;
      text-align: center;
      font-weight: 100;
      font-size: 20px;
      margin-bottom: 18px;
    }
  }

  @media ${Devices.laptop} {
    /* max-width: 540px;
    margin: 0 auto; */
  }

  h3 {
    font-size: 20px;
    line-height: 23px;
    color: ${Colors.nearBlack};
    margin-bottom: 19px;
    /* margin-left: 30px; */

    @media ${Devices.laptop} {
      font-size: 26px;
      line-height: 28px;
      margin-left: 0;
    }
  }
`

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 6px 6px;
  box-shadow: ${Colors.boxShadow};
  width: 33.3%;
  width: calc(33.3% - 12px);
  height: 87px;
  position: relative;

  @media ${Devices.laptop} {
    width: calc(25% - 12px);
  }

  span {
    font-size: 13px;
    line-height: 15px;
    display: block;
    text-align: center;
    font-family: baskerville-display-pt, serif;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
  }

  svg {
    /* height: 40px; */
    width: 50px;
    display: block;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 10px));
    max-height: 39px;
  }
`

const Padded = styled.div`
  margin: 0 30px;
  max-width: 600px;
  @media ${Devices.laptop} {
    margin: 0 auto;
    padding: 0 30px;
  }
`

const Address = styled.div`
  display: flex;
  margin: 15px 0 25px;
  span {
    font-size: 12px;
    line-height: 19px;
    margin-left: 15px;
    color: ${Colors.grey};
    font-weight: 500;
    @media ${Devices.laptop} {
      font-size: 16px;
      line-height: 22px;
    }
  }
`
const Description = styled.div`
  margin-bottom: 45px;
  p {
    color: ${Colors.grey};
    font-size: 15px;
    line-height: 20px;
    font-family: baskerville-display-pt, serif;
    white-space: break-spaces;

    @media ${Devices.laptop} {
      font-size: 17px;
      line-height: 23px;
    }
  }
`

const Title = styled.h2`
  font-size: 26px;
  line-height: 30px;
  color: ${Colors.nearBlack};
  padding-top: 5px;

  @media ${Devices.laptop} {
    font-size: 32px;
    line-height: 34px;
  }
`

const TornEdge = styled.div`
  background: url(${Torn}) center / 101% no-repeat;
  background-position: top !important;
  /* height: 20px; */
  padding-bottom: 5%;
  width: 100%;
  position: relative;
  right: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  transform: translateY(-99%);
`

const LocationWrapper = styled.div`
  margin: 0 0;
  max-width: 100%;
  height: 100vh;
  position: relative;
  background: #fff;

  .slick-arrow {
    background: #b6dde0 !important;
    z-index: 5;
    padding: 5px;
    width: auto;
    height: auto;
    border-radius: 4px;
    display: none !important;

    @media ${Devices.laptop} {
      display: block !important;
    }
  }

  .slick-next {
    right: 10px;
  }

  .slick-prev {
    left: 10px;
  }
`

const mapStateToProps = (state) => {
  return {
    location: state.location.data,
    layered: state.app.layered,
    bound: state.app.bound,
    locations: state.locations.locations,
    relatedLocations: state.locations.relatedLocations,
    dataLoading: state.app.dataLoading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadLocation: (slug) => {
      dispatch(DataActions.loadLocation(slug))
    },
    loadRelatedLocations: (location) => {
      dispatch(DataActions.loadRelatedLocations(location))
    },
    getWeather: (geoLocation) => {
      dispatch(LocationActions.getWeather(geoLocation))
    },
    setApp: (e) => {
      dispatch({
        type: "SET_APP",
        payload: e,
      })
    },
  }
}

const Index = connect(mapStateToProps, mapDispatchToProps)(Location)

export default React.memo(withRouter(Index))
