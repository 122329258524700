import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Colors from "../../consts/Colors";
import Devices from "../../consts/Devices";

const max = 3;

const CarouselDots = ({
  items,
  currentIndex,
  className,
  color,
  onLocation
}) => {
  const [copyItems, setCopyItems] = useState(items);

  // useEffect(() => {
  //   if (items.length > 0 && currentIndex && currentIndex > 0) {
  //     items = items.map(i => {
  //       i.active = false;
  //       return i;
  //     });
  //     items[currentIndex].active = true;

  //     setCopyItems(items);
  //   }
  // }, [items, currentIndex]);

  return (
    <Wrapper className={className} onLocation={onLocation}>
      <Inner>
        {items.map((item, i) => {
          return (
            <Dot
              key={i}
              color={color}
              active={i == currentIndex}
              hide={i > currentIndex + max || i < currentIndex - max}
            />
          );
        })}
        {/* // let shouldHide = false;
          // if (i >= 3) {
          //   shouldHide = true;
          // }
          // if (i == copyItems.length - 1) {
          //   shouldHide = false;
        //   // }
        //   let shouldHide = false;
        //   return (
        //     <Dot key={i} color={color} active={item.active} hide={shouldHide} />
        //   );
        // })}
      //  <span>
      //     <i>{currentIndex + 1}</i> / {items.length}
      //   </span>  */}
      </Inner>
    </Wrapper>
  );
};

export default CarouselDots;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${Devices.laptop} {
    display: ${props => (props.onLocation ? "flex" : "none")};
  }
`;

const Inner = styled.div`
  display: flex;

  span {
    font-size: 12px;
    i {
      font-style: normal;
      font-weight: 500;
      color: #696969;
    }
  }
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 9999em;
  border: 1px solid ${props => props.color};
  margin: 0 2px;
  transition: all 0.5s;
  width: ${props => (props.hide ? "0px" : "6px")};
  border-width: ${props => (props.hide ? "0px" : "1px")};
  margin: ${props => (props.hide ? "0px" : "0 2px")};
  opacity: ${props => (props.hide ? "0" : "1")};

  ${props =>
    props.active
      ? `
            background: ${props.color};
            border-color: ${props.color};
            width: 12px;
        `
      : ``}
`;
