import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

const PrivacyLoader = props => {
  useEffect(() => {
    props.setApp({ view: 'PRIVACY' });
  }, [props.match.params.slug]);
  return <span />;
};

export default withRouter(PrivacyLoader);
