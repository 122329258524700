import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import LocationsReducer from "./reducers/LoactionsReducer";
import AppReducer from "./reducers/AppReducer";
import ListsReducer from "./reducers/ListsReducer";
import LocationReducer from "./reducers/LocationReducer";
import ListReducer from "./reducers/ListReducer";

export default history =>
  combineReducers({
    router: connectRouter(history),
    locations: LocationsReducer,
    app: AppReducer,
    lists: ListsReducer,
    list: ListReducer,
    location: LocationReducer
  });
