import React from 'react';
import styled from 'styled-components/macro';
import Colors from '../../consts/Colors';
import { NavLink, withRouter } from 'react-router-dom';

const index = ({
  setApp,
  geoLocation,
  history,
  loaded,
  loadLocations,
  updateFilterTag,
  hide,
  loadGeo,
  seenSplash
}) => {
  if (window.location.pathname !== '/' || !seenSplash) {
    return <span />;
  }

  return (
    <Wrapper
      onClick={() => {
        if (window.navigator.geolocation) {
          setApp({
            seenSplash: true
          });
          loadGeo();
          window.navigator.geolocation.getCurrentPosition(
            loc => {
              setApp({
                userLocation: {
                  lat: loc.coords.latitude,
                  lng: loc.coords.longitude
                }
              });
              loadLocations({
                'fields.location[near]': `${loc.coords.latitude},${loc.coords.longitude}`,
                limit: 10
              });
              updateFilterTag(2);
            },
            () => {}
          );
        } else {
          alert('Geolocation is not supported by this browser.');
          setApp({
            seenSplash: true
          });
        }
      }}
      top={loaded}
      show={geoLocation}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="16.806" height="25.242">
        <g fill="#fff" data-name="Group 91">
          <path
            data-name="Path 36"
            d="M8.1.008A8.557 8.557 0 0 0 0 8.545a8.706 8.706 0 0 0 .7 2.763c.066.176.134.349.187.5a.61.61 0 0 0 .025.075l6.244 12.472a1.557 1.557 0 0 0 1.374.887h.012a1.559 1.559 0 0 0 1.374-.887l6.269-12.488a.611.611 0 0 0-.275-.812.59.59 0 0 0-.8.262L8.842 23.781a.322.322 0 0 1-.3.187.336.336 0 0 1-.312-.187L2 11.355c-.053-.147-.111-.3-.175-.475A7.934 7.934 0 0 1 1.2 8.545a7.35 7.35 0 0 1 14.424-2 .6.6 0 0 0 .737.425.616.616 0 0 0 .425-.749A8.582 8.582 0 0 0 8.542.008c-.147 0-.292-.012-.442 0z"
            fill-rule="evenodd"
          />
          <circle
            data-name="Ellipse 29"
            cx="2.225"
            cy="2.225"
            r="2.225"
            transform="translate(6.147 5.563)"
          />
        </g>
      </svg>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.15);
  border: 1px solid #fff;
  position: fixed;
  top: 17px;
  right: ${props => (props.show ? '99999px' : '20px')};
  z-index: 10;
  opacity: ${props => (props.show ? 0 : 1)};
  box-shadow: ${Colors.boxShadow};
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;
  transition: opacity 0.3s;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    path {
      color: #fff;
    }
  }
`;
export default withRouter(index);
