import React, { Component } from "react"
import styled from "styled-components/macro"
import Colors from "../../consts/Colors"
import Devices from "../../consts/Devices"
import { withRouter } from "react-router-dom"
import { TimelineLite } from "gsap"

import Grid from "../../assets/grid.png"

import ImageCarousel from "../ImageCarousel"
import CarouselDots from "../CarouselDots"

class Hero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      animated: false,
      currentIndex: 0,
    }
    this.el = React.createRef()
    this.tl = new TimelineLite({})
  }

  componentDidMount() {
    let ease = "Power1.easeInOut"
    this.tl.to(this.el, 0.9, {
      top: 0,
      ease,
      onComplete: () => {
        this.setState({ animated: true })
      },
    })
  }

  componentDidUpdate() {
    if (this.props.clearingLocation) {
      let ease = "Power1.easeInOut"
      this.tl.to(this.el, 0.4, {
        top: "100vh",
        ease,
      })
    }
  }

  render() {
    let {
      size,
      images,
      className,
      bound,
      onLocation,
      onClick,
      onLeft,
      intro,
      portrait,
    } = this.props
    return (
      <Wrapper
        ref={(el) => (this.el = el)}
        size={size}
        className={className}
        bound={bound}
        onLocation={onLocation}
        onClick={onClick}
        onLeft={onLeft}
        className="hero"
        intro={intro}
        portrait={portrait}
      >
        {/* <GridComp /> */}
        {images &&
          images.length > 1 &&
          (this.state.animated ? (
            <React.Fragment>
              <Dots
                items={images}
                currentIndex={this.state.currentIndex}
                color={"#fff"}
                onLocation={onLocation}
              />
              <ImageCarousel
                size={size}
                images={images}
                portrait={portrait}
                afterChange={(i) => {
                  this.setState({ currentIndex: i })
                }}
              />
            </React.Fragment>
          ) : (
            <Image size={size} onLeft={onLeft} image={images[0]} />
          ))}

        {images && images.length == 1 && (
          <Image size={size} onLeft={onLeft} image={images[0]} />
        )}
      </Wrapper>
    )
  }
}

const Dots = styled(CarouselDots)`
  position: absolute;
  bottom: 50px;
  right: 20px;
  z-index: 1;
  justify-content: flex-end;
`

const Image = styled.div`
  background: url(${(props) => props.image}?w=900);
  background-size: cover !important;
  background-position: center !important;
  min-height: ${(props) => (props.size == "large" ? "450px" : "200px")};

  @media ${Devices.laptop} {
    ${(props) =>
      props.onLeft
        ? `
          height: 100vh;
          `
        : ``}
  }
`

const Wrapper = styled.div`
  min-height: ${(props) => (props.size == "large" ? "450px" : "200px")};
  overflow: hidden;
  position: ${(props) => (props.bound ? "fixed" : "relative")};

  @media ${Devices.laptop} {
    ${(props) =>
      props.portrait &&
      `
      min-height: 740px;
      > div {
        min-height: 740px;
      }

    `}
  }

  img {
    width: 100%;
    min-width: 100%;
  }

  ${(props) =>
    props.onLocation &&
    `
        top: 100%;
        left: 0px;
        width: 100%;
        position: fixed;
        z-index: -1;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        `}

  @media ${Devices.laptop} {
    ${(props) =>
      props.intro &&
      `
        animation: fadeIn 2s;
          overflow: hidden;
          `}
  }

  @keyframes fadeIn {
    0% {
      filter: blur(5px);
      transform: scale(1.1);
    }
    100% {
      filter: blur(0px);
      transform: scale(1);
    }
  }

  @media ${Devices.laptop} {
    ${(props) =>
      props.onLeft
        ? `
          position: absolute;
          width: 65%!important;
          left: auto!important;
          `
        : ``}

    ${(props) =>
      props.onLocation
        ? `
          position: absolute;
          width: 100%!important;
          left: auto!important;
          `
        : ``}
  }

  @media ${Devices.layoutBreak} {
    ${(props) =>
      props.onLeft
        ? `
          width: calc(100% - 555px)!important;
          `
        : ``}
  }

  /* ${(props) =>
    props.bound
      ? `
        top: ${props.bound.top}px;
        left: ${props.bound.left}px;
        width: ${props.bound.width}px;
        height: ${props.bound.height}px;
        `
      : ``} */
`

const GridComp = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: url(${Grid});
  background-position: center;
  background-size: cover;
  z-index: 1;
  height: ${(props) => (props.loaded ? "33vh" : "100vh")};
`

export default withRouter(Hero)
